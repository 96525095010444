import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./CourseHeading.module.css";
import { Img } from "../elements/Img";
import Button from "../elements/Button";
import LanguageContext from "../LanguageContext";
import { translations } from "./translations";

interface Image {
  alt: string;
  url: string;
}

interface ICourseHeading {
  isWorkshop?: boolean;
  title: string;
  text?: string;
  soonestEditionDate?: string;
  priceLabel: string;
  price: string;
  priceText: string;
  graphics: Image;
}

const CourseHeading: React.FC<ICourseHeading> = ({
  isWorkshop,
  title,
  text,
  soonestEditionDate,
  priceLabel,
  price,
  priceText,
  graphics,
}) => {
  const { language } = useContext(LanguageContext);
  const createMarkup = (text?: string) => {
    if (!text) return;
    return { __html: text };
  };

  const courseScroll = () => {
    const availableCoursesSection = document.querySelector(".AvailableCourses");

    if (!availableCoursesSection) return;

    availableCoursesSection.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  return (
    <div className={styles.root}>
      <Container fluid="sm">
        <Row
          className={
            isWorkshop ? styles.workshopWithBorder : styles.withoutBorder
          }
        >
          <Col className={styles.contentWrapper}>
            <div>
              <h1 className={isWorkshop ? styles.workshopTitle : styles.title}>
                {title}
              </h1>
              {text && <p dangerouslySetInnerHTML={createMarkup(text)} />}
              {!isWorkshop && (
                <div className={styles.ctaWrapper}>
                  <div className={styles.buttonWrapper}>
                    <Button variant={"primary"} onClick={courseScroll}>
                      {translations[language].checkDatesAndPrice}
                    </Button>
                  </div>
                  <div>
                    <div className={styles.priceWrapper}>
                      <p className={styles.priceLabel}>{priceLabel}</p>
                      <h5 className={styles.price}>{price}</h5>
                    </div>
                    <p className={styles.text}>{priceText}</p>
                  </div>
                </div>
              )}
              {isWorkshop && (
                <>
                  <div className={styles.ctaWrapper}>
                    <h5
                      className={styles.ctaWorkshopSoonestLabel}
                      dangerouslySetInnerHTML={createMarkup(soonestEditionDate)}
                    />
                    <div className={styles.buttonWrapper}>
                      <Button variant={"primary"} onClick={courseScroll}>
                        {translations[language].joinToWorkshops}
                      </Button>
                    </div>
                  </div>
                  <div>
                    <div className={styles.priceWrapper}>
                      <p className={styles.priceLabel}>{priceLabel}</p>
                      <h5 className={styles.price}>{price}</h5>
                    </div>
                    <p className={styles.text}>{priceText}</p>
                  </div>
                </>
              )}
            </div>
            <div className={styles.graphicsWrapper}>
              {graphics && (
                <Img className={styles.graphics} src={graphics.url} />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CourseHeading;
