import { Languages } from "../LanguageContext";

const translations = {
  [Languages.English]: {
    joinToWorkshops: "Join to workshop",
    checkDatesAndPrice: "Check course dates and prices",
    checkDates: "Check available dates",
  },
  [Languages.German]: {
    joinToWorkshops: "Termine anzeigen",
    checkDatesAndPrice: "Kurstermine und Preise anzeigen",
    checkDates: "Kurstermine",
  },
};

export { translations };
