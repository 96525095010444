import React, { useContext } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import LanguageContext from "../../LanguageContext";

import styles from "./LevelModal.module.css";
import { MODAL_LEVELS_DSC } from "./LevelModalData";
import { translations } from "./translations";

interface ModalForm {
  modalShow: boolean;
  modalHide: () => void;
}

const LevelModal: React.FC<ModalForm> = ({ modalShow, modalHide }) => {
  const { language } = useContext(LanguageContext);

  return (
    <Modal
      show={modalShow}
      onHide={modalHide}
      aria-labelledby="contained-modal-title-vcenter"
      className={styles.modal}
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Row className={styles.modalWrapper}>
          <Col>
            <div className={styles.titleWrapper}>
              <h2 className={styles.modalTitle}>
                {translations[language].levelModalTitle}
              </h2>
              <p className={styles.modalSubtitle}>
                {translations[language].levelModalSubtitle}{" "}
                <a href="#verify_level">
                  {translations[language].levelModalSubtitleLink}
                </a>
              </p>
            </div>
            <div className={styles.gridWrapper}>
              {MODAL_LEVELS_DSC().map(({ heading, desc }) => (
                <div className={styles.singleLevel}>
                  <h5>{heading}</h5>
                  <p>{desc}</p>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default LevelModal;
