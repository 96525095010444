import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import styles from "./RichText.module.css";
import cx from "classnames";

enum Alignment {
  left = "left",
  center = "center",
  right = "right",
}

export interface RichTextProps {
  header: string;
  text: string;
  alignment: Alignment;
}

const RichText: React.FC<RichTextProps> = ({
  header,
  text,
  alignment = "left",
}) => {
  const createMarkup = (text: string) => {
    return { __html: text };
  };

  return (
    <Container
      className={styles.root}
      style={{
        //@ts-expect-error
        textAlign: alignment || "left",
      }}
    >
      <Row>
        <Col>
          {header && <h2>{header}</h2>}
          <div dangerouslySetInnerHTML={createMarkup(text)} />
        </Col>
      </Row>
    </Container>
  );
};

export default RichText;
