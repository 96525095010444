import React, { useEffect, useState } from "react";
import styles from "./Tag.module.css";
import { Img } from "../../elements/Img";
import Vector from "./images/Vector.svg";
import { IFilter } from "../../EventsFilter/EventsFilter";

export interface ITagProps {
  filter: IFilter;
  changeSelection: () => void;
}

export default function Tag({ filter, changeSelection }: ITagProps) {
  const [selectedButton, setSelectedButton] = useState(filter.selected);

  useEffect(() => {
    setSelectedButton(filter.selected);
  }, [filter]);

  const renderNotSelectedTag = () => {
    return (
      <div>
        <button className={styles.tagWrapper} onClick={changeSelection}>
          {filter.title}
        </button>
      </div>
    );
  };

  const renderSelectedTag = () => {
    return (
      <div>
        <button className={styles.selectedTagWrapper} onClick={changeSelection}>
          {filter.title}
          <Img src={Vector} />
        </button>
      </div>
    );
  };

  return (
    <>
      {!selectedButton && renderNotSelectedTag()}
      {selectedButton && renderSelectedTag()}
    </>
  );
}
