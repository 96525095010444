import React from "react";
import styles from "./OurStrengths.module.css";
import { Img } from "../elements/Img";
import { IStrength } from "./OurStrengths";
import { urlReplace } from "../../services/urlReplace";
import Link from "next/link";

const SingleStrength: React.FC<IStrength> = ({
  icon,
  titleStrength,
  textStrength,
  textLink,
  urlLink,
}) => {
  return (
    <div className={styles.SingleStrength}>
      {icon && <Img src={icon.url} />}
      <h5 className={styles.SingleStrengthTitle}>{titleStrength}</h5>
      <p className={styles.SingleStrengthText}>{textStrength}</p>
      {urlLink && textLink && (
        <Link href={urlReplace(urlLink)} passHref>
          <a>
            {textLink}
            <Img src={require("../elements/Card/assets/readMoreArrow.svg")} />
          </a>
        </Link>
      )}
    </div>
  );
};

export default SingleStrength;
