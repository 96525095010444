import { Languages } from "../LanguageContext";

const translations = {
  [Languages.English]: {
    category: "Category:",
    subcategory: "Subcategory:",
    level: "Level:",
    intensity: "Intensity:",
    timatable: "Timetable:",
    duration: "Duration:",
    learningUnits: "Learning units:",
    certificate: "Certificate:",
    learningMaterials: "Learning materials:",
    type: "Type:",
    participants: "Group:",
    agenda: "Agenda:",
  },
  [Languages.German]: {
    category: "Kategorie:",
    subcategory: "Unterkategorie:",
    level: "Kursniveau:",
    intensity: "Intensität:",
    timatable: "Stundenplan:",
    duration: "Kursdauer:",
    learningUnits: "Lerneinheiten:",
    certificate: "Sprachzeugnis:",
    learningMaterials: "Lernmaterial:",
    type: "Typ:",
    participants: "Gruppe:",
    agenda: "Agenda:",
  },
};

export { translations };
