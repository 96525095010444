import { IItem } from "./ShortFaq";
import styles from "./ShortFaq.module.css";
import { Img } from "../elements/Img";
import Link from "next/link";
import { urlReplace } from "../../services/urlReplace";

const ShortFaqContactItem: React.FC<IItem> = ({
  itemicon,
  itemtext,
  itemurl,
}) => {
  return (
    <div className={styles.contactItem}>
      <div className={styles.contactItemGraphicWrapper}>
        <Img src={itemicon?.url} />
      </div>
      <Link href={urlReplace(itemurl)}>
        <a className={styles.contactItemLink}>{itemtext}</a>
      </Link>
    </div>
  );
};
export default ShortFaqContactItem;
