import React from "react";
import styles from "./ContactCTAs.module.css";
import Link from "next/link";
import LinkButton from "../elements/LinkButton";

export interface IContactCTAs {
  title: string;
  links: { url: string; text: string }[];
  texts: { text: string }[];
}

const ContactCTAs: React.FC<IContactCTAs> = ({ title, links, texts }) => {
  return (
    <div className={styles.root}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.linksWrapper}>
        {links.length > 0 &&
          links.map(
            ({ url, text }) =>
              url &&
              text && (
                <LinkButton path={url} variant="primary">
                  {text}
                </LinkButton>
              )
          )}
      </div>
      {texts.map(({ text }) => (
        <p className={styles.singleHourInfo}>{text}</p>
      ))}
    </div>
  );
};

export default ContactCTAs;
