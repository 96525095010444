import React from "react";
import { Img } from "../../elements/Img";
import styles from "../WhatLearn.module.css";

interface Image {
  alt: string;
  url: string;
}

interface ITeacherBox {
  teacherTitle: string;
  teacherImage: Image;
  teacherName: string;
}

const TeacherBox: React.FC<ITeacherBox> = ({
  teacherTitle,
  teacherImage,
  teacherName,
}) => {
  return (
    <div className={styles.teacherRoot}>
      <h5>{teacherTitle}</h5>
      <Img src={teacherImage.url} className={styles.teacherImage} />
      <h5>{teacherName}</h5>
    </div>
  );
};
export default TeacherBox;
