import React from "react";
import styles from "./VideoComponent.module.css";

export interface VideoComponentProps {
  header: string;
  videoUrl: string;
  list: string[];
}

const VideoComponent: React.FC<VideoComponentProps> = (props) => {
  const createMarkup = (text: string) => {
    return { __html: text };
  };
  return (
    <>
      <h4 dangerouslySetInnerHTML={createMarkup(props.header)} />
      <div className={styles.wrapper}>
        {props.videoUrl ? (
          <iframe
            className={styles.iframe}
            width="560"
            height="315"
            src={`${props.videoUrl}?cc_load_policy=1&rel=0&showinfo=1&fs=1`}
            allowFullScreen
          ></iframe>
        ) : null}
      </div>
    </>
  );
};

export default VideoComponent;
