import Link from "next/link";
import React, { useContext } from "react";
import { urlReplace } from "../../../services/urlReplace";
import Button from "../../elements/Button";
import { Img } from "../../elements/Img";
import styles from "./Card.module.css";
import heart from "./assets/heart.svg";
import priceTag from "./assets/priceTag.svg";
import poland from "./assets/poland.svg";
import square from "./assets/square.svg";
import arrow from "./assets/arrow.svg";
import readMoreArrow from "./assets/readMoreArrow.svg";
import LanguageContext from "../../LanguageContext";
import { translations } from "../translations";

export interface CardProps {
  badge: string;
  header: string;
  image: string;
  blogStyle: boolean;
}
interface BlogType extends Omit<CardProps, "badge"> {
  date?: string;
  category?: string;
  text: string;
  blogPostUrl: string;
}
interface OfferType extends CardProps {
  price: string;
  pricePerMeter: number;
  plotType: string;
  plotLocation: string;
  heartIcon: boolean;
  badgeIcon: boolean;
}

interface CardGeneratorProps extends OfferType, BlogType {}

const CardGenerator: React.FC<Partial<CardGeneratorProps>> = ({
  header,
  text,
  badgeIcon,
  badge,
  image,
  heartIcon,
  blogStyle,
  pricePerMeter,
  plotType,
  plotLocation,
  price,
  date,
  blogPostUrl,
  category,
}) => {
  const { language } = useContext(LanguageContext);

  return (
    <div className={styles.root}>
      <div className={styles.ImageBackgroundWrapper}>
        <div className={styles.badgesWrapper}>
          {badge && (
            <div className={`${styles.badge} title small inverted`}>
              {badgeIcon && <Img src={priceTag} />}
              {badge}
            </div>
          )}
          {heartIcon && <Img src={heart} />}
        </div>
        <Img src={image} />
      </div>
      {blogStyle ? (
        <div className={styles.blogTextWrapper}>
          <h4 className="left">{header}</h4>
          {date && (
            <h6>
              {" "}
              {date} / {category}{" "}
            </h6>
          )}
          {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
          <div className={styles.readMoreWrapper}>
            <Link
              href={`/news-blog-deutsch-sprachschule/${blogPostUrl}`}
              passHref
            >
              <a>
                {translations[language].readMore}
                <Img src={readMoreArrow} />
              </a>
            </Link>
          </div>
        </div>
      ) : (
        <div className={`${styles.offerTextWrapper} small text`}>
          <h6 className="left green">
            {header}
            <Img src={arrow} />
          </h6>
          <p>
            <Img src={poland} />
            {plotLocation}
          </p>
          <p>
            {" "}
            <Img src={square} />
            {plotType}
          </p>
          <div className={styles.price}>
            <h6 className="left">{price}</h6>
            <p>{pricePerMeter}zł/m²</p>
          </div>
        </div>
      )}
    </div>
  );
};

const BlogCard: React.FC<BlogType> = (props) => <CardGenerator {...props} />;
const OfferCard: React.FC<OfferType> = (props) => <CardGenerator {...props} />;
export { BlogCard, OfferCard };
export default CardGenerator;
