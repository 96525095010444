import { Accordion, Card } from "react-bootstrap";
import { IAccordion } from "./ShortFaq";
import { Img } from "../elements/Img";

import accordionArrow from "./assets/accordionArrow.svg";

import styles from "./ShortFaq.module.css";
import { TreeFAQCategory } from "../../services/reducers/shortFaq";
import { useContext } from "react";
import LanguageContext from "../LanguageContext";

const AccordionItem: React.FC<TreeFAQCategory> = ({
  name,
  slug,
  parentElements,
}) => {
  const { language } = useContext(LanguageContext);

  return (
    <Card className={styles.shortFaqAccordion}>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={`${slug}`}
        className={styles.shortFaqAccordionHeader}
      >
        {name}
        <Img src={accordionArrow} />
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={`${slug}`}>
        <Card.Body>
          {parentElements.map(({ name, slug }) => (
            <a
              href={`${
                language != "de"
                  ? language + "/lcl-faq-german-language-school"
                  : "/sprachschule-faq-deutschsprachkursesprachpruefungen"
              }/${slug}`}
            >
              <p>{name}</p>
            </a>
          ))}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default AccordionItem;
