import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import CourseInformation from "../CourseInformation/CourseInformation";
import PaymentsDiscounts from "../PaymentsDiscounts/PaymentsDiscounts";
import RegistrationRequirements from "../RegistrationRequiremenets/RegistrationRequiremenets";
import SingleFeature from "../SingleFeature";
import { ISingleFeature } from "../SingleFeature/SingleFeature";
import styles from "./FeaturesGrid.module.css";

interface IFeaturesGrid {
  features: any;
}

interface IFeatureAttrs {
  attrs: ISingleFeature;
}

const FeaturesGrid: React.FC<IFeaturesGrid> = ({
  features: { attrs: featuresArray },
}) => {
  const [reducedFeaturesArray, setReducedFeaturesArray] = useState([]);

  useEffect(() => {
    const reducedFeatures = featuresArray.map(
      ({ attrs }: IFeatureAttrs) => attrs
    );
    setReducedFeaturesArray(reducedFeatures);
  }, [featuresArray]);

  return (
    <div className={styles.root}>
      <Container fluid="sm" className={styles.featuresGrid}>
        {reducedFeaturesArray &&
          reducedFeaturesArray.map((feature: ISingleFeature) => (
            <SingleFeature {...feature} />
          ))}
      </Container>
    </div>
  );
};
export default FeaturesGrid;
