import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AvailableCourse from "./AvailableCourse";

import { IAvailableCourse } from "./AvailableCourse";

import styles from "./AvailableCourses.module.css";
import LanguageContext from "../LanguageContext";
import { translations } from "./translations";

import rightCircle from "./assets/right-circle.svg";
import { Img } from "../elements/Img";

interface IAvailableCourses {
  courses: IAvailableCourse[];
}

const AvailableCourses: React.FC<IAvailableCourses> = ({ courses }) => {
  const { language } = useContext(LanguageContext);

  return (
    <div className={styles.rootImage}>
      <Img className={styles.rightCircle} src={rightCircle} />
      <Container className="AvailableCourses">
        <Row>
          <Col lg="3">
            <div className={styles.leftColumnContentWrapper}>
              <h2 className={styles.availableTitle}>
                {translations[language].availableDates}
              </h2>
            </div>
          </Col>
          <Col>
            {courses
              .filter(({ courseStart }) => {
                if (courseStart?.value) {
                  const date = new Date(
                    parseInt(courseStart?.value?.slice(0, 4), 10),
                    parseInt(courseStart?.value?.slice(4, 6), 10) - 1,
                    parseInt(courseStart?.value?.slice(6, 8), 10)
                  );
                  return date.getTime() > new Date().getTime();
                }
              })
              .sort(
                (
                  { courseStart: firstStartDate },
                  { courseStart: secondStartDate }
                ) => {
                  return (
                    +(firstStartDate?.value || 0) -
                    +(secondStartDate?.value || 0)
                  );
                }
              )
              .map((course) => (
                <AvailableCourse {...course} isOnCoursesList />
              ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default AvailableCourses;
