import React, { useContext } from "react";
import { Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styles from "./WhatLearn.module.css";
import { Img } from "../elements/Img";
import LinkButton from "../elements/LinkButton";

import tickIcon from "./assets/tickIcon.svg";
import TeacherBox from "./TeacherBox/TeacherBox";
import LanguageContext from "../LanguageContext/LanguageContext";
import { translations } from "../CourseHeading/translations";
import Button from "../elements/Button";

import infoTooltipIcon from "../elements/EventCard/assets/infoTooltipIcon.svg";
interface IItem {
  item: string;
}

interface Image {
  alt: string;
  url: string;
}

interface IWhatLearn {
  withBorderBottom?: string;
  globalTitle?: string;
  additionalTooltipInfo?: string;
  urlFirstColumn?: string;
  smallTitleFirstColumn?: string;
  teacherTitle?: string;
  teacherImage?: Image | null;
  teacherName?: string;
  secondTitle: string;
  secondText: string;
  thirdTitle?: string;
  textThirdColumn?: string;
  items: IItem[];
}

const WhatLearn: React.FC<IWhatLearn> = ({
  withBorderBottom,
  globalTitle,
  additionalTooltipInfo,
  smallTitleFirstColumn,
  urlFirstColumn,
  teacherTitle,
  teacherImage,
  teacherName,
  secondTitle,
  secondText,
  thirdTitle,
  textThirdColumn,
  items,
}) => {
  const { language } = useContext(LanguageContext);

  const createMarkup = (text: string) => {
    return { __html: text };
  };

  const renderTooltip = (props: any) => (
    <Tooltip {...props}>{additionalTooltipInfo}</Tooltip>
  );

  const scrollToForm = () => {
    const form = document.querySelector("#PrivateClasses");

    if (!form) return;
    form.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={styles.root}>
      <Container fluid="sm">
        <Row
          className={
            withBorderBottom
              ? styles.contentWrapperBorder
              : styles.contentWrapper
          }
        >
          <div className={styles.firstColumn}>
            {globalTitle && (
              <>
                <div className={styles.titleWrapper}>
                  <h2 className={styles.title}>{globalTitle}</h2>
                  {additionalTooltipInfo && (
                    <OverlayTrigger placement="top" overlay={renderTooltip}>
                      <Img src={infoTooltipIcon} />
                    </OverlayTrigger>
                  )}
                </div>
              </>
            )}
            {smallTitleFirstColumn && (
              <h3 className={styles.title}>{smallTitleFirstColumn}</h3>
            )}

            {teacherTitle && teacherImage && teacherName && (
              <TeacherBox
                teacherTitle={teacherTitle}
                teacherImage={teacherImage}
                teacherName={teacherName}
              />
            )}
          </div>
          <div className={styles.secondColumn}>
            {secondTitle && (
              <h4 className={styles.secondTitle}>{secondTitle}</h4>
            )}
            <p
              className={styles.secondText}
              dangerouslySetInnerHTML={createMarkup(secondText)}
            />
          </div>
          <div>
            {thirdTitle && <h4 className={styles.thirdTitle}>{thirdTitle}</h4>}
            {textThirdColumn && (
              <p
                className={styles.secondText}
                dangerouslySetInnerHTML={createMarkup(textThirdColumn)}
              />
            )}
            {items.map(({ item }: IItem) => (
              <div className={styles.singleItem}>
                <Img src={tickIcon} />
                <span>{item}</span>
              </div>
            ))}
          </div>
        </Row>
      </Container>
    </div>
  );
};
export default WhatLearn;
