import { IAdvantage } from "./Advantages";
import styles from "./Advantages.module.css";
import { Img } from "../elements/Img";

const AdvantageItem: React.FC<IAdvantage> = ({ text, icon, small = false }) => {
  return (
    <div
      className={`${styles.advantageContainer} ${small ? styles.small : ""}`}
    >
      <div
        className={
          small ? styles.iconBackgroundInverted : styles.iconBackground
        }
      >
        <Img src={icon.url} />
      </div>
      <h5 className={styles.text}>{text}</h5>
    </div>
  );
};
export default AdvantageItem;
