import React from "react";
import styles from "./OurTeam.module.css";
import { Container, Row } from "react-bootstrap";
import { Img } from "../elements/Img";
import TeamMember from "./TeamMember";

export interface ITeam {
  image: ITeamImage;
  name: string;
  linkBio: string;
}

interface ITeamImage {
  alt: string;
  url: string;
}

interface IOurTeam {
  title: string;
  team: ITeam[];
}

const OurTeam: React.FC<IOurTeam> = ({ title, team }) => {
  return (
    <div className={styles.ourTeamRoot}>
      <Container>
        <h2 className={styles.title}>{title}</h2>
        <Row className={styles.ourTeamWrapper}>
          {team.map((member: ITeam) => {
            return <TeamMember {...member} />;
          })}
        </Row>
      </Container>
    </div>
  );
};

export default OurTeam;
