import React from "react";
import { Container, Row } from "react-bootstrap";
import { urlReplace } from "../../services/urlReplace";
import Button from "../elements/Button";
import LinkButton from "../elements/LinkButton";
import styles from "./HowToJoin.module.css";
import HowToJoinStep from "./HowToJoinStep";

import leftSwirt from "./assets/left-swirt.svg";
import { Img } from "../elements/Img";

export interface IStep {
  stepnumber: string;
  icon: IImage;
  title: string;
  text: string;
}

interface IImage {
  alt: string;
  url: string;
}

interface IHowToJoin {
  title: string;
  steps: IStep[];
  buttonText?: string;
  buttonUrl?: string;
  textUnderButton?: string;
}

const HowToJoin: React.FC<IHowToJoin> = ({
  title,
  steps,
  buttonText,
  buttonUrl,
  textUnderButton,
}) => {
  const createMarkup = (text: string) => ({
    __html: text,
  });

  return (
    <div className={styles.root}>
      <Img className={styles.leftSwirt} src={leftSwirt} />
      <Container fluid="sm">
        <h2 className={styles.title}>{title}</h2>
        <Row className={styles.stepsWrapper}>
          <div className={styles.stepsSpacer}></div>
          {steps.map((step: IStep) => {
            return <HowToJoinStep key={step.stepnumber} {...step} />;
          })}
        </Row>
        {(buttonText || textUnderButton) && (
          <Row className={styles.buttonWrapper}>
            {buttonText && (
              <Button variant={"primary"}>
                <a className={styles.buttonLink} href={buttonUrl}>
                  {buttonText}
                </a>
              </Button>
            )}
            {textUnderButton && (
              <p
                dangerouslySetInnerHTML={createMarkup(textUnderButton)}
                className={styles.textUnderButton}
              />
            )}
          </Row>
        )}
      </Container>
    </div>
  );
};
export default HowToJoin;
