import Link from "next/link";
import React, { ReactNode } from "react";
import Button from "../elements/Button";
import OpinionCard, { OpinionCardProps } from "./OpinionCard/OpinionCard";
import styles from "./Opinions.module.css";
import { urlReplace } from "../../services/urlReplace";
import { Container } from "react-bootstrap";
import { Img } from "../elements/Img";
import Slider from "react-slick";
import { isOptionDisabled } from "react-select/src/builtins";

const TSlider = Slider as unknown as React.FC<any>;

export interface OpinionsProps {
  image: { link: string; description: string };
  header: string;
  buttonText: string;
  buttonUrl: string;
  buttonIcon: { link: string };
  opinions: OpinionCardProps[];
}

const Opinions: React.FC<OpinionsProps> = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          variableWidth: true,
        },
      },
    ],
  };

  return (
    <Container className={styles.wrapper}>
      <h3 className={styles.title}>{props.header}</h3>
      <TSlider {...settings} className={styles.slider}>
        {props.opinions
          ? props.opinions.map((singleOpinion: any) => (
              <OpinionCard opinions={singleOpinion} />
            ))
          : []}
      </TSlider>
    </Container>
  );
};

export default Opinions;
