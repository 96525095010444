import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Event from "../elements/EventCard";
import { Courses } from "../../services/wordpressApi";
import Filter from "../Filter";
import {
  basicDurationFilter,
  basicIntensityFilters,
  basicLanguageFilters,
  basicLevelFilters,
  basicPriceFilter,
} from "../../consts/Filters";
import { applyFilters } from "../Filter/utils";
import { isMobile } from "react-device-detect";
import filterIcon from "./images/filterIcon.svg";
import arrowBack from "./images/arrowBack.svg";
import { Img } from "../elements/Img";
import { translations } from "./translations";
import LanguageContext from "../LanguageContext";
import { useRouter } from "next/router";
import queryString from "query-string";

import Slider from "react-slick";
import { NextArrow, PrevArrow } from "../EventsCarousel/EventsCarousel";

import leftSwirt from "./images/left-swirt.svg";
import rightSwirt from "./images/right-swirt.svg";
import leftCircle from "./images/left-circle.svg";

import styles from "./EventsFilter.module.css";
import cx from "classnames";

const VISIBLE_COURSES_PER_PAGE = 10;
const PER_PAGE_COURSES_FOR_YOU = 9;

export interface IEventsFilterProps {
  header: string;
  courses: Courses[];
  isCoursesForYou?: boolean;
}

export interface IFilter {
  title: string;
  selected: boolean;
  query: IQuery[];
}

export interface INumberFilter {
  title: string;
  priceFrom: string;
  priceTo: string;
  query: INumberQuery;
}

export interface IQuery {
  field: string;
  value: string;
}

export interface INumberQuery {
  field: string;
  valueMin: number;
  valueMax: number;
}

export default function EventsFilter(props: IEventsFilterProps) {
  const { language } = React.useContext(LanguageContext);

  const router = useRouter();

  const { query } = router;
  let filters = query.filters ? JSON.parse(query.filters as string) : {};

  const initialRows: Courses[][] = [];
  const [perPageCourses, setPerPageCourses] = useState<Courses[]>([]);
  const [rows, setRows] = useState(initialRows);
  const [isFiltered, setIsFiltered] = useState(false);

  const [languageFilters, setLanguageFilters] = useState(
    filters.languageFilters || basicLanguageFilters()
  );
  const [levelFilters, setLevelFilters] = useState(
    filters.levelFilters || basicLevelFilters
  );
  const [intensityFilters, setIntensityFilters] = useState(
    filters.intensityFilters || basicIntensityFilters()
  );
  const [priceFilter, setPriceFilter] = useState(
    filters.priceFilter || basicPriceFilter()
  );
  const [durationFilter, setDurationFilter] = useState(
    filters.durationFilter || basicDurationFilter()
  );
  const [mobileFilterOverlay, setMobileFilterOverlay] = useState(false);

  useEffect(() => {
    sliceCoursesPerPage();
  }, []);

  const sliceCoursesPerPage = () => {
    const startValue = perPageCourses.length;
    const endValue = perPageCourses.length + PER_PAGE_COURSES_FOR_YOU;

    const initialCoursesPerPage = props.courses.slice(startValue, endValue);
    setPerPageCourses((prevState) => [
      ...new Set([...prevState, ...initialCoursesPerPage]),
    ]);
  };

  useEffect(() => {
    let newRows = reduceCoursesToRows(
      applyFilters(
        props.courses,
        [languageFilters, levelFilters, intensityFilters],
        [priceFilter, durationFilter]
      )
    ).slice(0);
    setRows(newRows);

    // router.push(
    //   {
    //     pathname: router.pathname,
    //     query: {
    //       ...queryString.parse(router.asPath.split(/\?/)[1]),
    //       filters: JSON.stringify({
    //         languageFilters: languageFilters,
    //         levelFilters: levelFilters,
    //         intensityFilters: intensityFilters,
    //         priceFilter,
    //         durationFilter,
    //       }),
    //     },
    //   },
    //   undefined,
    //   { shallow: true }
    // );
  }, [
    languageFilters,
    levelFilters,
    intensityFilters,
    priceFilter,
    durationFilter,
    perPageCourses,
  ]);

  useEffect(() => {
    const isFilter = [...languageFilters, ...levelFilters].some(
      (filter: IFilter) => filter.selected
    );

    setIsFiltered(isFilter);
  }, [
    languageFilters,
    levelFilters,
    intensityFilters,
    priceFilter,
    durationFilter,
    perPageCourses,
    isFiltered,
  ]);

  useEffect(() => {
    if (Object.keys(filters).length > 0) return;

    let newRows = reduceCoursesToRows(perPageCourses).slice(0);
    setRows(newRows);
  }, [perPageCourses]);

  const reduceCoursesToRows = (coursesToSlice: Courses[]) => {
    let slicedRows: Courses[][] = [];
    let index = 0;
    while (index < coursesToSlice.length) {
      slicedRows.push(
        coursesToSlice.slice(index, (index += props.isCoursesForYou ? 3 : 2))
      );
    }
    return slicedRows;
  };

  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    centerMode: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const parseSelectedFilters = (filtersName: IFilter[], name: string) => {
    const newFiltersFromQuery = filtersName.map((filter: IFilter) => {
      if (filters?.[name]?.[0].includes(filter.query[0].value)) {
        return { ...filter, selected: true };
      }

      return filter;
    });

    return newFiltersFromQuery;
  };

  const renderMobileCourses = () => {
    return (
      <Slider {...settings}>
        {rows.map((row) =>
          row.map((event) => (
            <div className={styles.eventWrapper}>
              <Event {...event} />
            </div>
          ))
        )}
      </Slider>
    );
  };

  const renderFilter = () => {
    return (
      <Col>
        <Filter
          isCoursesForYou={props.isCoursesForYou}
          languageFilters={languageFilters}
          setLanguageFilters={setLanguageFilters}
          levelFilters={levelFilters}
          setLevelFilters={setLevelFilters}
          intensityFilters={intensityFilters}
          setIntensityFilters={setIntensityFilters}
          priceFilter={priceFilter}
          setPriceFilter={setPriceFilter}
          durationFilter={durationFilter}
          setDurationFilter={setDurationFilter}
        />
      </Col>
    );
  };

  const renderFilterMobile = () => {
    return (
      <Col>
        <div>
          <button
            className={styles.closeFilterButton}
            onClick={() => setMobileFilterOverlay(false)}
          >
            <Img src={arrowBack} />
            {translations[language].goBack}
          </button>
        </div>
        <Filter
          isCoursesForYou={props.isCoursesForYou}
          languageFilters={languageFilters}
          setLanguageFilters={setLanguageFilters}
          levelFilters={levelFilters}
          setLevelFilters={setLevelFilters}
          intensityFilters={intensityFilters}
          setIntensityFilters={setIntensityFilters}
          priceFilter={priceFilter}
          setPriceFilter={setPriceFilter}
          durationFilter={durationFilter}
          setDurationFilter={setDurationFilter}
        />
      </Col>
    );
  };

  useEffect(() => {
    if (Object.values(filters).length > 0) {
      const newLanguageFiltersFromQuery = parseSelectedFilters(
        languageFilters,
        "language"
      );
      const newLevelFiltersFromQuery = parseSelectedFilters(
        levelFilters,
        "level"
      );

      setLanguageFilters(newLanguageFiltersFromQuery);
      setLevelFilters(newLevelFiltersFromQuery);
    }
  }, []);

  return (
    <div
      className={cx(styles.root, {
        [styles.isCoursesForYou]: props.isCoursesForYou,
      })}
    >
      <Img className={styles.leftSwirt} src={leftSwirt} />
      <Img className={styles.leftCircle} src={leftCircle} />
      <Img className={styles.rightSwirt} src={rightSwirt} />
      <Container>
        <Row className={styles.mainRow}>
          {!isMobile && renderFilter()}
          {!mobileFilterOverlay && (
            <Col>
              {isMobile && (
                <Row className={styles.filterCoursesWrapper}>
                  <div>
                    <button
                      className={styles.switchFilterButton}
                      onClick={() => setMobileFilterOverlay(true)}
                    >
                      <Img src={filterIcon} />{" "}
                      {translations[language].filterCourses}
                    </button>
                  </div>
                </Row>
              )}
              <div className={styles.coursesContainer}>
                {!isMobile &&
                  rows.length > 0 &&
                  rows.map((row) => (
                    <Row className={styles.coursesRow}>
                      {row.map((event) => (
                        <Col
                          key={`course-card-${event.id}`}
                          className={styles.eventCardColumn}
                        >
                          <div className={styles.eventCardWrapper}>
                            <Event {...event} />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  ))}

                {!isMobile &&
                  props.courses.length > perPageCourses.length &&
                  rows.length > 0 &&
                  !isFiltered && (
                    <button
                      className={styles.loadMoreButton}
                      onClick={() => sliceCoursesPerPage()}
                    >
                      {translations[language].loadMore}
                    </button>
                  )}

                {!isMobile && rows.length <= 0 && (
                  <div
                    className={cx(styles.emptyListText, {
                      [styles.isCoursesForYou]: props.isCoursesForYou,
                    })}
                  >
                    {translations[language].emptyList}
                  </div>
                )}
              </div>
            </Col>
          )}
          {mobileFilterOverlay && renderFilterMobile()}
          {isMobile && renderMobileCourses()}
        </Row>
      </Container>
    </div>
  );
}
