import { Languages } from "../../LanguageContext";

const translations = {
  [Languages.English]: {
    levelModalTitle: "Common European framework of reference for languages",
    levelModalSubtitle:
      "Are you not sure what your current level is? We'll help you figure it out!",
    levelModalSubtitleLink: "Verify your level",
    a1LevelHeading: "Beginner A1 (A1.1 – A1.2)",
    a1LevelDesc:
      "The speaker can use familiar, everyday expressions, very simple sentences, introduce themselves and others, ask questions about themselves and respond to questions of this type. The speaker can communicate in a simple manner when spoken slowly and clearly.",
    a2LevelHeading: "Basic knowledge A2 (A2.1. - A2.2) ",
    a2LevelDesc:
      "The speaker is able to understand sentences and frequently used expressions to the following subjects: personal life, family, shopping, work, neighborhood. In simple, daily conversations, the speaker can communicate about familiar topics and can describe their own background, education, environment and immediate needs.",
    b1LevelHeading: "Advanced language use B1 (B1.1. – B1.2 – B1.+)",
    b1LevelDesc:
      "The speaker can understand important information on familiar topics, such as work, school, leisure, etc., when standard language is used. The speaker is able to deal with most situations encountered when traveling to the respective language area. Furthermore, the speaker can talk about familiar subjects, interests, experiences and events coherently and with ease. Lastly, the speaker is able to describe dreams, hopes, goals and briefly justify or explain plans and opinions.",
    b2LevelHeading: "Independent use of language B2 (B2.1. - B2.2 - B2+)",
    b2LevelDesc:
      "The speaker can understand the essentials of complex texts on both concrete and abstract topics, as well as technical discussions in their own field of specialization. The speaker can hold normal conversations with native speakers without strain on either side. The speaker can express themselves clearly and in detail on many topics, explain points of view on current issues and present advantages and disadvantages.",
    c1LevelHeading: "Professional language skills C1 (C1.1 - C1.2 - C1+)",
    c1LevelDesc:
      "The speaker can understand demanding, longer texts and grasp implicit meaning. The speaker can talk fluently and spontaneously and use the language effectively and flexibly in social, professional, educational and academic contexts. The speaker is able to express themselves clearly, in a structured and detailed way on complex subjects and use text linking devices appropriately.",
    cLevelHeading: "Approximate native language proficiency C2 (C2.1. - C2.2)",
    cLevelDesc:
      "The speaker can easily understand almost everything by reading or listening. The speaker can summarize information from written and spoken sources and give coherent reasons and explanations. The speaker can express themselves spontaneously, with great fluency and accuracy, and can make clear finer nuances of meaning in more complex situations.",
  },
  [Languages.German]: {
    levelModalTitle: "Gemeinsamer Europäischer Referenzrahmen für Sprachen",
    levelModalSubtitle:
      "Sie wissen nocht nicht, welches Sprachlevel Sie haben? Kein Problem – wir stufen Sie gern ein!",
    levelModalSubtitleLink: "Einstufung",
    a1LevelHeading: "Anfänger A1 (A1.1 – A1.2)",
    a1LevelDesc:
      "Er:sie kann auf elementarer Basis vertraute, alltägliche Ausdrücke, sehr einfache Sätze verstehen und verwenden, sich und andere vorstellen, anderen Fragen zu ihrer Person stellen. Er:sie kann konkrete Bedürfnisse äußern, Fragen stellen und beantworten. Er:sie  kann sich auf einfache Art verständigen, wenn langsam und deutlich gesprochen wir, sowie kurze, einfache Texte lesen und schreiben.",
    a2LevelHeading: "Grundlegende Kenntnisse A2 (A2.1 – A2.2)",
    a2LevelDesc:
      "Er.sie kann auf elemenrarer gefestigterer Basis Sätze und häufig gebrauchte Ausdrücke z. B. zu Person, Familie, Einkaufen, Arbeit, Reisen und Nachbarschaft verstehen. Er:sie kann sich in routinemäßigen Situationen des Alltags- und Berufslebens zu vertrauten Themen und Tätigkeiten verständigen. Er:sie kann mit einfachen Mitteln die eigene Herkunft, Ausbildung, direkte Umgebung und unmittelbare Bedürfnisse mitteilen, sowie kuze, zusammenhängende Meldungen und Texte verstehen, lesen und schreiben.",
    b1LevelHeading: "Fortgeschrittene Sprachverwendung B1 (B1.1 – B1.2)",
    b1LevelDesc:
      "Er:sie kann sich in zunehmend selsbtsändger Sprachverwendung  Hauptpunkte im eigenen Sprachgebiet zu vertrauten Dingen wie z.B. Arbeit oder Freizeit verstehen, wenn klare Standardsprache verwendet wird. Er:sie kann einfach zusammenhämgend zu vertrauten Themen wie Familie, Arbeit, Interessen verständich ausdrücken. Er:sie kann über  Erfahrungen und Ereignisse berichten, Träume, Hoffnungen und Ziele beschreiben sowie Pläne und Ansichten begründen und erklären. Er:sie kann frei sprechend  seine:ihre Wortwahl planen und korrigieren, sowie zusammenhängende Dialoge verstehen und Alltagstexte und Meinungen lesen und schreiben",
    b2LevelHeading: "Selbstständige Sprachverwendung B2 (B2.1 – B2.2)",
    b2LevelDesc:
      "Er:sie kann in selbständig den Hauptinhalt komplexer Texte zu konkreten und abstrakten Themen verstehen und ein normales Gespräch mit Muttersprachlern ohne größere Anstrengung führen, Gespräche beginnen und führen. Er:sie kann sich spontan und fließend im gleichmäßigen Tempo verständigen und im eigenen Spezialgebiet an Diskussionen teilnehmen. Er:sie kann sich zu einem breiten Themenspektrum klar und detailliert ausdrücken, Standpunkte zu allgemeinen, aktuellen Fragen erläutern und Vor- und Nachteile angeben. Er:sie kann Missverständisse vermeiden, die meisten Fehler selbst korrigieren sowie zusammenhängende Beiträge zu begrenzten Themen verstehen und schreiben.",
    c1LevelHeading: "Fachkundige Sprachkenntnisse C1 ( C1.1 – C1.2)",
    c1LevelDesc:
      "Er:sie kann ein breites Spektrum an anspruchsvollen, längeren Texten und implizite Bedeutungen verstehen, sich beinahne mühelos ohne erkennbare Einschränkung spontan und fließend ausdrücken, klar und angemessen formulieren. Er:sie kann die Sprache im gesellschaftlichen und beruflichen Leben, in Ausbildung und Studium wirksam und flexibel gebrauchen. Er:sie kann sich klar, strukturiert und ausführlich zu komplexen Sachverhalten äußern und dabei dabei verschiedene Diskurmittel zur Textverknüpfung angemessen verwenden. Er:sie besitzt ein durchgehend hohes Maß an grammatischer Korrektheit, wobei Fehler  selten sind, selbst korrigiert werden und kaum auffallen. Er:sie kann bis hin  zu vielfältige zusammenhängende Beiträge zu Fachthemen verstehen, sich dazu äußern und komplexe Textformate wiedergeben.",
    cLevelHeading: "Annährend muttersprachliche Kenntnisse C2 (C2.1 – C2.2",
    cLevelDesc:
      "Er:sie verwendet die Sprache kompetent und versteht mühelos fast alles, was er:sie hört oder liest, kann sich im spontanen, natürlichen Sprechfluss zu längeren Beiträgen fast fehlerlos äußern und sich selbst unauffällig korrigieren. Er:sie kann Informationen aus verschiedenen schriftlichen und mündlichen Quellen zusammenfassen und dabei Begründungen und Erklärungen in einer zusammenhängenden Darstellung wiedergeben. Er:sie behrrscht durchgehend die Grammatik, kann sich spontan, sehr flüssig mithilfe verschiedener komplexer Stilmittel bis hin zu idiomatischen Wendungen auch feinere, akzentuierte oder differenzierte Bedeutungsnuancen deutlich machen. Er:sie kann sich gewandt verständigen, Intonation einsetzen, natürlich das Wort ergreifen und auf Anspielungen reagieren, um vorauschauend zu planen und  kohärent verknüpfte Gliederungen vorzunehmen, sowohl mündlich als auch schrifltich.",
  },
};

export { translations };
