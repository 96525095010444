import Link from "next/link";
import React from "react";
import { Img } from "../elements/Img";

import { urlReplace } from "../../services/urlReplace";

import arrowRight from "./assets/arrowRight.svg";

import styles from "./SingleFeature.module.css";

export interface ISingleFeature {
  icon: { url: string };
  title: string;
  text: string;
  url?: string;
}
const SingleFeature: React.FC<ISingleFeature> = ({
  icon,
  title,
  text,
  url,
}) => {
  return (
    <div className={url ? styles.rootLink : styles.root}>
      <div className={styles.contentWrapper}>
        <div className={styles.iconWrapper}>
          {icon && <Img src={icon.url} />}
        </div>
        <h4
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p className={styles.text}>{text}</p>
      </div>
      {url && (
        <Link href={urlReplace(url)} passHref>
          <div className={styles.arrowButton}>
            <Img src={arrowRight} />
          </div>
        </Link>
      )}
    </div>
  );
};

export default SingleFeature;
