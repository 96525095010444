import React, { useContext } from "react";
import { Img } from "../elements/Img";
import { iconPicker, labelPicker } from "./helpers";

import infoTooltipIcon from "../elements/EventCard/assets/infoTooltipIcon.svg";

import styles from "./CourseInformation.module.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LinkButton from "../elements/LinkButton";
import { translations } from "./translations";
import LanguageContext from "../LanguageContext";
import Link from "next/link";

interface ISingleInformation {
  label: string;
  text: string;
  additionalInfo: string;
}
const SingleInformation: React.FC<ISingleInformation> = ({
  label,
  text,
  additionalInfo,
}) => {
  const { language } = useContext(LanguageContext);

  const renderTooltip = (props: any) => (
    <Tooltip {...props}>{additionalInfo}</Tooltip>
  );

  return (
    <div className={styles.SingleInformation}>
      {label && (
        <div className={styles.labelWrapper}>
          <Img src={iconPicker(label)} />
          <p className={styles.label}>{labelPicker(label)}</p>
        </div>
      )}
      <div className={styles.answerWrapper}>
        <div className={styles.answerWrapperTooltip}>
          {text && (
            <p
              className={styles.answer}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          {additionalInfo && (
            <OverlayTrigger placement="top" overlay={renderTooltip}>
              <Img src={infoTooltipIcon} />
            </OverlayTrigger>
          )}
        </div>
        {label === "level" && (
          <>
            <p className={styles.notSure}>{translations[language].notSure}</p>
            <Link href="#verify_level" passHref>
              <a className={styles.contactUs}>
                {translations[language].contactUs}
              </a>
            </Link>
          </>
        )}
        {/* {label === "timetable" && (
          <div className={styles.linkWrapper}>
            <LinkButton path="#" variant="primary">
              Check available dates
            </LinkButton>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default SingleInformation;
