import Link from "next/link";
import React, { useContext } from "react";
import { Accordion, Card } from "react-bootstrap";
import { Container, Row } from "react-bootstrap";
import { TreeFAQCategory } from "../../services/reducers/shortFaq";
import Button from "../elements/Button";
import { Img } from "../elements/Img";
import LanguageContext from "../LanguageContext";
import AccordionItem from "./AccordionItem";
import styles from "./ShortFaq.module.css";
import ShortFaqContactItem from "./ShortFaqContactItem";
import { translations } from "./translations";

import leftSwirt from "./assets/left-swirt.svg";

export interface IAccordion {
  accordiontitle: string;
  accordiontext: string;
  index?: string | number;
}

export interface IItem {
  itemicon: IImage;
  itemtext: string;
  itemurl: string;
}

interface IImage {
  alt: string;
  url: string;
}

interface IShortFaq {
  faqtitle: string;
  faqsubtitle: string;
  faqaccordions: TreeFAQCategory[];
  contacttitle: string;
  contacttext: string;
  contactitems: IItem[];
}

const ShortFaq: React.FC<IShortFaq> = ({
  faqtitle,
  faqsubtitle,
  faqaccordions,
  contacttitle,
  contacttext,
  contactitems,
}) => {
  const { language } = useContext(LanguageContext);

  return (
    <div className={styles.root}>
      <Img className={styles.leftSwirt} src={leftSwirt} />
      <Container fluid="sm">
        <div className={styles.contentWrapper}>
          <div className={styles.shortFaqWrapper}>
            <h2 className={styles.shortFaqTitle}>{faqtitle}</h2>
            <p className={styles.shortFaqSubtitle}>{faqsubtitle}</p>
            <Accordion
              className={styles.shortFaqAccordions}
              defaultActiveKey={faqaccordions[0].slug}
            >
              {faqaccordions.map((accordion: TreeFAQCategory) => (
                <AccordionItem {...accordion} />
              ))}
            </Accordion>
            <div className={styles.readAllFaqBtnWrapper}>
              <Link
                href={`${
                  language === "en"
                    ? "/lcl-faq-german-language-school"
                    : "/sprachschule-faq-deutschsprachkursesprachpruefungen"
                }`}
              >
                <a>
                  <Button variant={"primary"}>
                    {translations[language].readAllFaqs}
                  </Button>
                </a>
              </Link>
            </div>
          </div>
          <div className={styles.contactWrapper}>
            <h2 className={styles.contactTitle}>{contacttitle}</h2>
            <p className={styles.contactText}>{contacttext}</p>
            {contactitems.map((item: IItem) => (
              <ShortFaqContactItem {...item} />
            ))}
            <div className={styles.contactButtonsWrapper}>
              <Link href="#verify_level">
                <a>
                  <Button variant={"primary"}>
                    {translations[language].bookApointment}
                  </Button>
                </a>
              </Link>
              <a
                href={`${
                  language === "en"
                    ? "/en/german-language-school-lab-vienna-contact-us"
                    : "/kontakt-lcl-sprachschule-deutsch-sprachen-labor"
                }`}
              >
                <Button variant={"secondary"}>
                  {translations[language].sendMessage}
                </Button>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ShortFaq;
