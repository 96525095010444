import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./YourNeeds.module.css";
import { Img } from "../elements/Img";
import Link from "next/link";

import contactIcon from "./assets/contactIcon.svg";
import YourNeedsForm from "./YourNeedsForm/YourNeedsForm";

interface Image {
  alt: string;
  url: string;
}

interface IYourNeeds {
  title: string;
  text: string;
  buttonText: string;
  buttonUrl: string;
  image: Image;
}

const YourNeeds: React.FC<IYourNeeds> = ({
  title,
  text,
  buttonText,
  buttonUrl,
  image,
}) => {
  return (
    <div className={styles.root}>
      <Container fluid="sm">
        <Row className={styles.rowGap}>
          <Col className={styles.leftColumn}>
            <h2 className={styles.title}>{title}</h2>
            <p>{text}</p>
            <Link href={buttonUrl}>
              <a className={styles.contactLink}>
                <Img src={contactIcon} />
                {buttonText}
              </a>
            </Link>
            <Img src={image.url} className={styles.lastImage} />
          </Col>
          <Col>
            <YourNeedsForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default YourNeeds;
