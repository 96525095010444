import Link from "next/link";
import React, { useState } from "react";
import { Collapse, Container } from "react-bootstrap";
import Button from "../elements/Button";
import { Img } from "../elements/Img";
import LinkButton from "../elements/LinkButton";
import styles from "./Categories.module.css";
import arrowDown from "./img/arrowDown.svg";
import SingleCard from "./SingleCard";

export interface CategoriesProps {
  header: string;
  seeMoreButton: string;
  seeLessButton: string;
  cards: {
    image: string;
    header: string;
    url: string;
  }[];
}

const Categories: React.FC<CategoriesProps> = ({
  header,
  seeMoreButton,
  seeLessButton,
  cards,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Container className={styles.root}>
      <h4>{header}</h4>
      <div className={styles.cardsWrapper}>
        {cards
          ? cards.map(
              ({ header, image, url }: any, index) =>
                index < 4 && (
                  <SingleCard
                    header={header}
                    image={image}
                    key={index}
                    url={url}
                  />
                )
            )
          : null}
      </div>
      <Collapse in={open}>
        <div className={styles.cardsWrapper}>
          {cards
            ? cards.map(
                ({ header, image, url }: any, index) =>
                  index >= 4 && (
                    <SingleCard
                      header={header}
                      image={image}
                      key={index}
                      url={url}
                    />
                  )
              )
            : null}
        </div>
      </Collapse>
      <h6
        className={`${open && styles.rotated} green boldLink`}
        onClick={() => setOpen(!open)}
        aria-expanded={open}
      >
        {open ? seeLessButton : seeMoreButton}
        <Img src={arrowDown} className={styles.rotated} />
      </h6>
    </Container>
  );
};

export default Categories;
