import { Languages } from "../LanguageContext";

const translations = {
  [Languages.English]: {
    clearSelection: "Clear selection",
    filterCourses: "Filter courses",
    coursePrice: "Price for the course",
    languages: "Languages",
    additionalText: "Additional text",
    level: "Level",
    levelAdditionalText: "Level Additional text",
    courseIntensity: "Course intensity",
    courseIntensityAdditionalText: "Course intensity",
    courseDuration: "Course duration",
    german: "German",
    english: "English",
    latin: "Latin",
    french: "French",
    spanish: "Spanish",
    austrian: "Austrian sign language",
    intensive: "Intensive",
    semiIntensive: "Compact",
    regular: "Regular",
    price: "Price",
    duration: "Duration",
    entireCourse: "entire course",
  },
  [Languages.German]: {
    clearSelection: "Filter zurücksetzen",
    filterCourses: "Kurse filtern",
    coursePrice: "Preis für den Kurs",
    languages: "Sprache",
    additionalText: "Sprache",
    level: "Niveau",
    levelAdditionalText: "Niveau",
    courseIntensity: "Intensität",
    courseIntensityAdditionalText: "Kursintensität",
    courseDuration: "Dauer (in Std.)",
    german: "Deutsch",
    english: "Englisch",
    latin: "Latein",
    french: "Französisch",
    spanish: "Spanisch",
    austrian: "Ö. Gebärdensrache",
    intensive: "Intensiv",
    semiIntensive: "Kompakt",
    regular: "Regulär",
    price: "Preis",
    duration: "Dauer",
    entireCourse: "Gesamtkurs",
  },
};

export { translations };
