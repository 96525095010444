import * as React from "react";
import { FadeIn } from "../FadeIn";
import ColumnsContainer from "../ColumnsContainer";
import Spacer from "../elements/Spacer";
import ServicesComponent from "../ServicesComponent";
import VideoComponent from "../VideoComponent";
import Opinions from "../Opinions";
import Facts from "../Facts";
import CallToAction from "../elements/CallToAction";
import Categories from "../Categories";
import RichText from "../RichText";
import TextWithImages from "../TextWithImages";
import OfferList from "../OffersList/OffersList";
import EventsCarousel from "../EventsCarousel";
import Advantages from "../Advantages/Advantages";
import HowToJoin from "../HowToJoin/HowToJoin";
import EventsFilter from "../EventsFilter";
import ShortFaq from "../ShortFaq/ShortFaq";
import SingleFeature from "../SingleFeature/SingleFeature";
import WhatLearn from "../WhatLearn/WhatLearn";
import CourseInformation from "../CourseInformation/CourseInformation";
import CourseHeading from "../CourseHeading/CourseHeading";
import OurStrengths from "../OurStrengths/OurStrengths";
import PaymentsDiscounts from "../PaymentsDiscounts/PaymentsDiscounts";
import RegistrationRequirements from "../RegistrationRequiremenets/RegistrationRequiremenets";
import CourseDetails from "../CourseDetails/CourseDetails";
import ContactUs from "../ContactUs/ContactUs";
import FormalData from "../FormalData/FormalData";
import ContactCTAs from "../ContactCTAs/ContactCTAs";
import FeaturesGrid from "../FeaturesGrid/FeaturesGrid";
import AvailableCourses from "../AvailableCourses/AvailableCourses";
import Map from "../Map";
import OurTeam from "../OurTeam";
import OurPartners from "../OurPartners/OurPartners";
import TextComponent from "../TextComponent";
import BlogArticles from "../BlogArticles";
import YourNeeds from "../YourNeeds/YourNeeds";
import PrivateClasses from "../PrivateClasses/PrivateClasses";
import WithModuleText from "../WithModalText/WithModalText";
import ExamTraining from "../ExamTraining/ExamTraining";

import dynamic from "next/dynamic";
import Head from "next/head";

const GridLightGallery = dynamic(
  () => import("../GridLightGallery/GridLightGallery"),
  {
    ssr: false,
  }
);
const ContactForm = dynamic(() => import("../ContactForm"), {
  ssr: false,
});

const ContactFormWrapper = dynamic(
  () => import("../ContactFormWrapper/ContactFormWrapper"),
  {
    ssr: false,
  }
);

export interface IComposerBlocksProps {
  data: any[];
}

export default function ComposerBlocks({ data }: IComposerBlocksProps) {
  return (
    <>
      {data.map(({ id, attrs }, index) => (
        <FadeIn isVisibleProp={index === 0} key={`${id}-${index}`}>
          {(function () {
            switch (id) {
              case "lazyblock/facts":
                return <Facts {...attrs} key={`facts-${id}-${index}`} />;
              case "core/columns":
                return <ColumnsContainer {...attrs} />;
              case "lazyblock/spacer":
                return <Spacer {...attrs} />;
              case "lazyblock/video-component":
                return <VideoComponent {...attrs} />;
              case "lazyblock/services":
                return <ServicesComponent {...attrs} />;
              case "lazyblock/opinions":
                return <Opinions {...attrs} />;
              case "lazyblock/call-to-action":
                return <CallToAction {...attrs} />;
              case "lazyblock/text-with-images":
                return <TextWithImages {...attrs} />;
              case "lazyblock/categories":
                return <Categories {...attrs} />;
              case "lazyblock/rich-text":
                return <RichText {...attrs} />;
              case "lazyblock/offers-list":
                return <OfferList {...attrs} />;
              case "lazyblock/courses":
                return <EventsCarousel {...attrs} />;
              case "lazyblock/advantages":
                return <Advantages {...attrs} />;
              case "lazyblock/ourpartners":
                return <OurPartners {...attrs} />;
              case "lazyblock/how-to-join":
                return <HowToJoin {...attrs} />;
              case "lazyblock/courses-fillter-grid":
                return <EventsFilter key={JSON.stringify(attrs)} {...attrs} />;
              case "lazyblock/short-faq":
                return <ShortFaq {...attrs} />;
              case "lazyblock/single-feature":
                return <SingleFeature {...attrs} />;
              case "lazyblock/course-information":
                return <CourseInformation {...attrs} />;
              case "lazyblock/course-heading":
                return <CourseHeading {...attrs} />;
              case "lazyblock/our-strengths":
                return <OurStrengths {...attrs} />;
              case "lazyblock/what-learn":
                return <WhatLearn {...attrs} />;
              case "lazyblock/payments-discounts":
                return <PaymentsDiscounts {...attrs} />;
              case "lazyblock/registration-requirements":
                return <RegistrationRequirements {...attrs} />;
              case "lazyblock/course-details":
                return <CourseDetails {...attrs} />;
              case "lazyblock/contact-us":
                return <ContactUs {...attrs} />;
              case "lazyblock/formal-data":
                return <FormalData {...attrs} />;
              case "lazyblock/contact-form":
                return <ContactForm {...attrs} />;
              case "lazyblock/contact-form-wrapper":
                return <ContactFormWrapper {...attrs} />;
              case "lazyblock/contact-ctas":
                return <ContactCTAs {...attrs} />;
              case "lazyblock/features-grid":
                return <FeaturesGrid {...attrs} />;
              case "lazyblock/courses-available":
                return <AvailableCourses {...attrs} />;
              case "lazyblock/mapcomponent":
                return <Map {...attrs} />;
              case "lazyblock/ourteam":
                return <OurTeam {...attrs} />;
              case "lazyblock/text-block":
                return <TextComponent {...attrs} />;
              case "lazyblock/blogarticles":
                return <BlogArticles {...attrs} />;
              case "lazyblock/grid-light-gallery":
                return <GridLightGallery {...attrs} />;
              case "lazyblock/your-needs":
                return <YourNeeds {...attrs} />;
              case "lazyblock/private-classes":
                return <PrivateClasses {...attrs} />;
              case "lazyblock/exam-training":
                return <ExamTraining {...attrs} />;
              case "lazyblock/with-modal-text":
                return <WithModuleText {...attrs} />;

              // case "core/heading":
              //   return <HtmlBlock {...attrs} />;
              // case "core/paragraph":
              //   return <HtmlBlock {...attrs} />;
              // case "core/separator":
              //   return <HtmlBlock {...attrs} />;
              default:
                return <></>;
            }
          })()}
        </FadeIn>
      ))}
    </>
  );
}
