import React, { useContext } from "react";
import styles from "./OurTeam.module.css";
import { Img } from "../elements/Img";
import { ITeam } from "./OurTeam";
import Link from "next/link";

import personIcon from "./assets/person.svg";
import LanguageContext from "../LanguageContext";
import { translations } from "./translations";

const TeamMember: React.FC<ITeam> = ({ image, name, linkBio }) => {
  const { language } = useContext(LanguageContext);

  return (
    <div className={styles.teamMember}>
      {image?.url && <Img src={image.url} alt={image.alt} />}
      {name && <h5 className={styles.teamMemberName}>{name}</h5>}
      {linkBio && <p className={styles.teamMemberReadBio}>{linkBio}</p>}
    </div>
  );
};

export default TeamMember;
