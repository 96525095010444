import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import LinkButton from "../elements/LinkButton";
import styles from "./OffersList.module.css";
import iconCheckmark from "../elements/icons/ico-checkmark.svg";

export interface OfferListProps {
  header: string;
  description: string;
  offers: {
    text: string;
    url: string;
  }[];
}

export default function OfferList(props: OfferListProps) {
  const createMarkup = (text: string) => {
    return { __html: text };
  };
  return (
    <Container>
      <Row className={styles.container}>
        <div className={styles.headerColumn}>
          <h2 dangerouslySetInnerHTML={createMarkup(props.header)} />
          <p dangerouslySetInnerHTML={createMarkup(props.description)}></p>
        </div>
        <div className={styles.linkColumn}>
          {props.offers?.map(({ text, url }) => (
            <div className={styles.linkWrapper} key={text}>
              <LinkButton path={url} variant="primary">
                {text}
              </LinkButton>
            </div>
          ))}
        </div>
      </Row>
    </Container>
  );
}
