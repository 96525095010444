import React, { useContext } from "react";
import styles from "./ContactUs.module.css";
import Link from "next/link";
import LinkButton from "../elements/LinkButton";
import LanguageContext from "../LanguageContext";
import { translations } from "./translations";
import { urlReplace } from "../../services/urlReplace";
import { SocialProps } from "../../services/reducers/contactUs";
import SingleSocial from "./SingleSocial/SingleSocial";
import WhatsAppBtn from "../WhatsAppBtn/WhatsAppBtn";

interface IContactUs {
  title: string;
  phoneNumber: string;
  mail: string;
  hoursTitle: string;
  hours: { hour: string }[];
  socials: SocialProps[];
}

const ContactUs: React.FC<IContactUs> = ({
  title,
  phoneNumber,
  mail,
  hoursTitle,
  hours,
  socials,
}) => {
  const { language } = useContext(LanguageContext);

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.topContactDetails}>
        <Link href={`tel:${phoneNumber}`}>
          <div className={styles.contactFlex}>
            <WhatsAppBtn />
            <a className={styles.phoneNumber}>{phoneNumber}</a>
          </div>
        </Link>
        <Link href={`mailto:${mail}`}>
          <a className={styles.mail}>{mail}</a>
        </Link>
      </div>
      <div>
        <h6 className={styles.hoursTitle}>{hoursTitle}</h6>
        {hours.map(({ hour }) => (
          <p className={styles.singleHourInfo}>{hour}</p>
        ))}
      </div>
      {socials.length > 0 && (
        <div className={styles.socialsWrapper}>
          {socials.map((social: SocialProps) => (
            <SingleSocial {...social} />
          ))}
        </div>
      )}
      <LinkButton
        path={urlReplace(
          language === "en"
            ? "/en/about-us-language-school-vienna-german-course-laguuages-oeif-exam-preparation#map"
            : "/sprachschule-sprachlabor-management-wien-1070#map"
        )}
        variant="primary"
      >
        {translations[language].findUs}
      </LinkButton>
    </div>
  );
};

export default ContactUs;
