import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ComposerBlocks from "../ComposerBlocks";
import styles from "./ColumnsContainer.module.css";
import cx from "classnames";
import { Img } from "../elements/Img";

import rightSwirt from "./assets/right-swirt.svg";

export interface IMenuSidebarProps {
  columns: any[];
  menu: any[];
}

export default function ColumnsContainer(props: IMenuSidebarProps) {
  const { columns } = props;
  const treeFirstColumns = columns.length > 3 ? columns.slice(0, 2) : columns;
  let sizes = [4, 4, 4];
  if (treeFirstColumns[0] && treeFirstColumns[0].width) {
    sizes = [Math.round((12 * treeFirstColumns[0].width.slice(0, -1)) / 100)];

    if (treeFirstColumns[1].width) {
      sizes[1] = Math.round(
        (12 * treeFirstColumns[1].width.slice(0, -1)) / 100
      );
    } else {
      sizes[1] = Math.round(12 - sizes[0] / 2);
    }
    sizes[2] = 12 - sizes[0] - sizes[1];
  }

  return (
    <div className={styles.root}>
      <Img className={styles.rightSwirt} src={rightSwirt} />
      <Container className={cx(styles.container)}>
        <Row>
          {treeFirstColumns[0] && (
            <Col
              className={styles.firstColumn}
              xs={{ order: "first", span: 12 }}
              xl={{ span: sizes[0] }}
            >
              <ComposerBlocks data={treeFirstColumns[0].innerBlocks} />
            </Col>
          )}
          {treeFirstColumns[1] && (
            <Col
              className={styles.secondColumn}
              xs={{ order: "first", span: 12 }}
              xl={{ span: sizes[1] }}
            >
              <ComposerBlocks data={treeFirstColumns[1].innerBlocks} />
            </Col>
          )}
          {treeFirstColumns[2] && (
            <Col
              className={styles.thirdColumn}
              xs={{ order: "first", span: 12 }}
              xl={{ span: sizes[2] }}
            >
              <ComposerBlocks data={treeFirstColumns[2].innerBlocks} />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}
