import React, { useContext } from "react";
import LanguageContext from "../../LanguageContext";
import { translations } from "./translations";

export const MODAL_LEVELS_DSC = () => {
  const { language } = useContext(LanguageContext);

  return [
    {
      heading: translations[language].a1LevelHeading,
      desc: translations[language].a1LevelDesc,
    },
    {
      heading: translations[language].a2LevelHeading,
      desc: translations[language].a2LevelDesc,
    },
    {
      heading: translations[language].b1LevelHeading,
      desc: translations[language].b1LevelDesc,
    },
    {
      heading: translations[language].b2LevelHeading,
      desc: translations[language].b2LevelDesc,
    },
    {
      heading: translations[language].c1LevelHeading,
      desc: translations[language].c1LevelDesc,
    },
    {
      heading: translations[language].cLevelHeading,
      desc: translations[language].cLevelDesc,
    },
  ];
};
