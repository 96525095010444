import React, { useContext, useState, useEffect } from "react";
import Tag from "../Tag";
import { Img } from "../../elements/Img";
import Vector from "./images/Vector.svg";
import { IFilter } from "../../EventsFilter/EventsFilter";
import LanguageContext from "../../LanguageContext";
import { translations } from "../translations";

import styles from "./FilterTagSection.module.css";
import cx from "classnames";

export interface IFilterTagSection {
  isCoursesForYou?: boolean;
  filters: IFilter[];
  updateFilters: (filters: IFilter[]) => void;
}

export default function FilterTagSection({
  isCoursesForYou = false,
  filters,
  updateFilters,
}: IFilterTagSection) {
  const { language } = useContext(LanguageContext);
  const [selectedFilters, setSelectedFilters] = useState(filters);

  const clearSelectedFilters = () => {
    const newFilters: IFilter[] = [];
    selectedFilters.map((filter) =>
      newFilters.push({ ...filter, selected: false })
    );
    setSelectedFilters(newFilters);
    updateFilters(newFilters);
  };

  const changeSelection = (filterName: string) => {
    const newFilters: IFilter[] = [];
    selectedFilters.map((filter) =>
      filter.title === filterName
        ? newFilters.push({ ...filter, selected: !filter.selected })
        : newFilters.push(filter)
    );
    setSelectedFilters(newFilters);
    updateFilters(newFilters);
  };

  const shouldRenderClearButton = () => {
    return selectedFilters.some((el) => el.selected);
  };

  useEffect(() => {
    setSelectedFilters(filters);
  }, [filters]);

  return (
    <>
      <div
        className={cx(styles.root, {
          [styles.isCoursesForYou]: isCoursesForYou,
        })}
      >
        <div className={styles.tagsWrapper}>
          {selectedFilters.map((filter) => (
            <Tag
              key={`tag-filter-${filter.title}`}
              filter={filter}
              changeSelection={() => changeSelection(filter.title)}
            />
          ))}
        </div>
      </div>
      {shouldRenderClearButton() && (
        <button
          className={styles.clearFiltersButton}
          onClick={clearSelectedFilters}
        >
          <Img src={Vector} />
          {translations[language].clearSelection}
        </button>
      )}
    </>
  );
}
