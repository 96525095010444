import categoryIcon from "./assets/categoryIcon.svg";
import subcategoryIcon from "./assets/subcategoryIcon.svg";
import levelIcon from "./assets/levelIcon.svg";
import intensityIcon from "./assets/intensityIcon.svg";
import timetableIcon from "./assets/timetableIcon.svg";
import durationIcon from "./assets/durationIcon.svg";
import learningUnitsIcon from "./assets/learningUnitsIcon.svg";
import certificateIcon from "./assets/certificateIcon.svg";
import learningMaterialsIcon from "./assets/learningMaterialsIcon.svg";
import participantsIcon from "./assets/participantsIcon.svg";
import { useContext } from "react";
import LanguageContext from "../LanguageContext";
import { translations } from "../CourseDetails/translation";

export const INFORMATIONS_LABELS = () => {
  const { language } = useContext(LanguageContext);

  return [
    {
      id: "category",
      label: translations[language].category,
      icon: categoryIcon,
    },
    {
      id: "subcategory",
      label: translations[language].subcategory,
      icon: subcategoryIcon,
    },
    {
      id: "level",
      label: translations[language].level,
      icon: levelIcon,
    },
    {
      id: "intesity",
      label: translations[language].intensity,
      icon: intensityIcon,
    },
    {
      id: "timetable",
      label: translations[language].timatable,
      icon: timetableIcon,
    },
    {
      id: "duration",
      label: translations[language].duration,
      icon: durationIcon,
    },
    {
      id: "units",
      label: translations[language].learningUnits,
      icon: learningUnitsIcon,
    },
    {
      id: "certificate",
      label: translations[language].certificate,
      icon: certificateIcon,
    },
    {
      id: "materials",
      label: translations[language].learningMaterials,
      icon: learningMaterialsIcon,
    },
    {
      id: "type",
      label: translations[language].type,
      icon: categoryIcon,
    },
    {
      id: "participants",
      label: translations[language].participants,
      icon: participantsIcon,
    },
    {
      id: "agenda",
      label: translations[language].agenda,
      icon: timetableIcon,
    },
  ];
};

const labelElementPicker = (id: string) => {
  const labelElement = INFORMATIONS_LABELS().find(
    ({ id: labelId }) => id === labelId
  );
  return labelElement;
};

export const labelPicker = (label: string) => {
  const labelElem = labelElementPicker(label);
  return labelElem?.label;
};

export const iconPicker = (label: string) => {
  const labelElem = labelElementPicker(label);
  return labelElem?.icon;
};
