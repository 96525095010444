import React, { useContext, useState } from "react";
import { Form, InputGroup, Alert } from "react-bootstrap";
import Button from "../../elements/Button";
import { useFormik, Field, FormikProvider } from "formik";
import { DOMAIN_URL } from "../../../services/wordpressApi";

import styles from "../../ModalForms/ModalForms.module.css";
import LanguageContext from "../../LanguageContext";
import CheckboxForms from "../../ModalForms/CheckboxForms/CheckboxForms";
import PlusMinusForms from "../../ModalForms/PlusMinusForms/PlusMinusForms";
import { translations } from "../../ModalForms/translations";
import {
  COURSE_PLACE_FOR_BUSINESS,
  TEACHING_SYSTEM,
} from "../../ModalForms/EstimateB2BForm/EstimateB2BFormData";
import { useRouter } from "next/router";
import PlusMinutInput from "../../ModalForms/PlusMinusForms/PlusMinutInput";

export interface MyCheckboxProps {
  field: any;
  form: any;
  label: any;
  value: any;
}

const YourNeedsForm = () => {
  const { language } = useContext(LanguageContext);
  const router = useRouter();
  const [requestStatus, setRequestStatus] = React.useState<{
    status: string;
    message: string;
  } | null>(null);

  const [howOftenState, setHowOftenState] = useState(1);

  const formik = useFormik({
    initialValues: {
      teachingSystem: "",
      courseParticipation: "5",
      coursePlace: "",
      howOften: howOftenState.toString(),
    },
    enableReinitialize: true,
    onSubmit: async (data, actions) => {
      try {
        const formData = new FormData();
        Object.entries(data).forEach(([dataKey, dataValue]) => {
          formData.append(dataKey, dataValue);
        });

        router.push(
          {
            pathname:
              language === "en"
                ? "/en/language-courses-for-companies"
                : "/sprachkurse-workshops-fuer-ihre-firma-und-unternehmen-wien",
            query: data,
            hash: "estimate_b2b",
          },
          undefined,
          { shallow: true }
        );

        actions.setSubmitting(false);
      } catch (e) {
        setRequestStatus({
          status: "failed",
          message: "error",
        });
      }
    },
  });

  return (
    <div className={styles.rootYourNeedsForm}>
      <FormikProvider value={formik}>
        <div className={styles.estimateTitleWrapper}>
          <h3 className={styles.modalTitle}>
            {translations[language].yourNeedsTitle}
          </h3>
        </div>

        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className={styles.estimateWrapper} controlId="formReason">
            <Form.Label className={styles.bigFormLabel}>
              1. {translations[language].teachingSystem}
            </Form.Label>
            <div className={styles.radiosWrapper}>
              {TEACHING_SYSTEM().map((system) => (
                <Field
                  name="teachingSystem"
                  id="check"
                  label={system}
                  value={system}
                  isRadio
                  customType="your-needs"
                  component={CheckboxForms}
                />
              ))}
            </div>
          </Form.Group>
          <Form.Group
            className={styles.estimateWrapper}
            controlId="formBasicRange"
          >
            <Form.Label className={styles.bigFormLabel}>
              2. {translations[language].courseParticipation}
            </Form.Label>
            <div className={styles.rangeWrapper}>
              <Form.Control
                type="range"
                min={5}
                max={20}
                name="courseParticipation"
                onChange={formik.handleChange}
                value={formik.values.courseParticipation}
              />
              <div className={styles.rangeValueBox}>
                {formik.values.courseParticipation}
              </div>
            </div>
          </Form.Group>
          <Form.Group className={styles.estimateWrapper} controlId="formReason">
            <Form.Label className={styles.bigFormLabel}>
              3. {translations[language].coursePlace}
            </Form.Label>
            <div className={styles.radiosWrapper}>
              {COURSE_PLACE_FOR_BUSINESS().map((place) => (
                <Field
                  name="coursePlace"
                  id="check"
                  label={place}
                  value={place}
                  isRadio
                  customType="your-needs"
                  component={CheckboxForms}
                />
              ))}
            </div>
          </Form.Group>

          <Form.Group className={styles.estimateWrapper} controlId="formReason">
            <Form.Label className={styles.bigFormLabel}>
              4. {translations[language].howOften}
            </Form.Label>
            <div className={styles.radiosWrapper}>
              <Field
                name="howOften"
                id="check"
                label={formik.values.howOften}
                value={formik.values.howOften}
                customType="your-needs"
                component={PlusMinutInput}
              />
            </div>
          </Form.Group>

          <Form.Group>
            <div className={styles.button}>
              {requestStatus && (
                <Alert
                  className={styles.alert}
                  variant={
                    requestStatus.status === "mail_sent" ? "success" : "danger"
                  }
                >
                  {requestStatus.message}
                </Alert>
              )}

              <Button variant="primary" type="submit">
                {translations[language].offerButton}
              </Button>
            </div>
          </Form.Group>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default YourNeedsForm;
