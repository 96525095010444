import React, { useState } from "react";

import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Img } from "../../elements/Img";
import infoTooltipIcon from "../../elements/EventCard/assets/infoTooltipIcon.svg";
import styles from "./FilterHeading.module.css";
import LevelModal from "../LevelModal/LevelModal";

export interface IFilterHeadingProps {
  title: string;
  additionalText?: string;
  levelModal?: boolean;
}

export default function FilterHeading({
  title,
  additionalText,
  levelModal = false,
}: IFilterHeadingProps) {
  const [levelModalVisible, setLevelModalVisible] = useState(false);

  const renderTooltip = (props: any) => (
    <Tooltip {...props}>{additionalText}</Tooltip>
  );

  const showLevelModal = () => {
    setLevelModalVisible(true);
  };

  return (
    <div className={styles.headingWrapper}>
      <h6>{title}</h6>
      {!levelModal && additionalText && (
        <OverlayTrigger placement="top" overlay={renderTooltip}>
          <Img src={infoTooltipIcon} />
        </OverlayTrigger>
      )}
      {levelModal && (
        <>
          <Button onClick={showLevelModal} className={styles.levelModalButton}>
            <Img src={infoTooltipIcon} />
          </Button>

          <LevelModal
            modalShow={levelModalVisible}
            modalHide={() => {
              setLevelModalVisible(false);
            }}
          />
        </>
      )}
    </div>
  );
}
