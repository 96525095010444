import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./PriceSlider.module.css";
import { INumberFilter } from "../../EventsFilter/EventsFilter";
import LanguageContext from "../../LanguageContext";
import { translations } from "../translations";

export interface IPriceSliderProps {
  priceFrom: string;
  priceTo: string;
  currencySign: string;
  filter: INumberFilter;
  setPriceFilter: (filter: INumberFilter) => void;
}

export default function PriceSlider({
  priceFrom,
  priceTo,
  currencySign,
  filter,
  setPriceFilter,
}: IPriceSliderProps) {
  const { language } = useContext(LanguageContext);
  const progressRef = useRef(null);
  const [minValue, setMinValue] = useState(priceFrom);
  const [maxValue, setMaxValue] = useState(priceTo);

  const handleMin = (e: ChangeEvent<HTMLInputElement>) => {
    if (
      !isNaN(parseInt(e.target.value)) ||
      parseInt(e.target.value) >= parseInt(priceFrom)
    ) {
      if (parseInt(maxValue) <= parseInt(priceTo)) {
        if (parseInt(e.target.value) < parseInt(maxValue)) {
          setMinValue(e.target.value);
        }
      } else if (parseInt(e.target.value) < parseInt(minValue)) {
        setMinValue(e.target.value);
      }
    }
  };

  const handleMax = (e: ChangeEvent<HTMLInputElement>) => {
    if (
      !isNaN(parseInt(e.target.value)) ||
      parseInt(e.target.value) <= parseInt(priceTo)
    ) {
      if (parseInt(maxValue) <= parseInt(priceTo)) {
        if (parseInt(e.target.value) > parseInt(minValue)) {
          setMaxValue(e.target.value);
        }
      } else if (parseInt(e.target.value) < parseInt(maxValue)) {
        setMaxValue(e.target.value);
      }
    }
  };

  useEffect(() => {
    if (progressRef.current != null) {
      const element = progressRef.current as HTMLElement;
      const minSliderPosition =
        parseInt(minValue) / parseInt(priceTo) > 0
          ? parseInt(minValue) / parseInt(priceTo)
          : 0;

      element.style.left = minSliderPosition * 100 + "%";

      const maxSliderPosition =
        parseInt(maxValue) / parseInt(priceTo) < 1
          ? 1 - parseInt(maxValue) / parseInt(priceTo)
          : 0;
      element.style.right = maxSliderPosition * 100 + "%";

      setPriceFilter({
        ...filter,
        query: {
          ...filter.query,
          valueMax: parseInt(maxValue),
          valueMin: parseInt(minValue),
        },
      });
    }
  }, [minValue, maxValue]);

  return (
    <div className={styles.root}>
      <div className={styles.priceInterval}>
        <h4>{priceFrom}</h4>
        <h4>-</h4>
        <h4>{priceTo}</h4>
        <p>/ {translations[language].entireCourse}</p>
      </div>
      <div className={styles.sliderWrapper}>
        <input
          className={styles.numberInput}
          type={"number"}
          value={minValue}
          onChange={(e) => setMinValue(e.target.value)}
        />
        <div className={styles.sliderInputWrapper}>
          <div className={styles.slider}>
            <div className={styles.progress} ref={progressRef} />
          </div>
          <div className={styles.rangeInput}>
            <input
              type={"range"}
              step={1}
              min={priceFrom}
              max={priceTo}
              value={isNaN(parseInt(minValue)) ? 0 : minValue}
              onChange={handleMin}
            />
            <input
              type={"range"}
              step={1}
              min={priceFrom}
              max={priceTo}
              value={isNaN(parseInt(maxValue)) ? 0 : maxValue}
              onChange={handleMax}
            />
          </div>
        </div>
        <input
          className={styles.numberInput}
          type={"number"}
          value={maxValue}
          onChange={(e) => setMaxValue(e.target.value)}
        />
      </div>
    </div>
  );
}
