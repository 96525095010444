import React, { useContext } from "react";
import FilterHeading from "./FilterHeading";
import PriceSlider from "./PriceSlider";
import FilterTagSection from "./FilterTagSection";
import { IFilter, INumberFilter } from "../EventsFilter/EventsFilter";
import { translations } from "./translations";
import LanguageContext from "../LanguageContext";
import { useRouter } from "next/router";
import { LATIN_SLUG_EN } from "./utils";

import styles from "./Filter.module.css";
import cx from "classnames";

export interface IFilterProps {
  isCoursesForYou?: boolean;
  languageFilters: IFilter[];
  setLanguageFilters: (filters: IFilter[]) => void;
  levelFilters: IFilter[];
  setLevelFilters: (filters: IFilter[]) => void;
  intensityFilters: IFilter[];
  setIntensityFilters: (filters: IFilter[]) => void;
  priceFilter: INumberFilter;
  setPriceFilter: (filter: INumberFilter) => void;
  durationFilter: INumberFilter;
  setDurationFilter: (filter: INumberFilter) => void;
}

export default function Filter({
  isCoursesForYou = false,
  languageFilters,
  setLanguageFilters,
  levelFilters,
  setLevelFilters,
  intensityFilters,
  setIntensityFilters,
  priceFilter,
  setPriceFilter,
  durationFilter,
  setDurationFilter,
}: IFilterProps) {
  const { language } = useContext(LanguageContext);
  const { query } = useRouter();

  const isLatin = () => {
    const { slug } = query;

    const isLatinSearch = languageFilters?.some(
      ({ title, selected }) =>
        title === translations[language].latin && selected
    );
    const isLatinPage = slug === LATIN_SLUG_EN;

    return isLatinSearch || isLatinPage;
  };

  return (
    <div
      className={cx(styles.root, { [styles.isCoursesForYou]: isCoursesForYou })}
    >
      {!isCoursesForYou && (
        <div className={styles.filterCourses}>
          <h3>{translations[language].filterCourses}</h3>
        </div>
      )}
      <div className={styles.filterSectionWrapper}>
        <FilterHeading title={translations[language].languages} />
        <FilterTagSection
          isCoursesForYou
          updateFilters={setLanguageFilters}
          filters={languageFilters}
        />
      </div>
      {!isCoursesForYou && (
        <div className={styles.filterSectionWrapper}>
          <FilterHeading title={priceFilter.title + " (in EUR)"} />
          <PriceSlider
            priceFrom={priceFilter.priceFrom}
            priceTo={priceFilter.priceTo}
            currencySign={"€"}
            filter={priceFilter}
            setPriceFilter={setPriceFilter}
          />
        </div>
      )}
      {!isLatin() && (
        <div className={styles.filterSectionWrapper}>
          <FilterHeading title={translations[language].level} levelModal />
          <FilterTagSection
            isCoursesForYou
            updateFilters={setLevelFilters}
            filters={levelFilters}
          />
        </div>
      )}
      {!isCoursesForYou && (
        <div className={styles.filterSectionWrapper}>
          <FilterHeading title={translations[language].courseIntensity} />
          <FilterTagSection
            updateFilters={setIntensityFilters}
            filters={intensityFilters}
          />
        </div>
      )}
      {!isCoursesForYou && (
        <div className={styles.filterSectionWrapper}>
          <FilterHeading title={durationFilter.title + " (in Std.)"} />
          <PriceSlider
            priceFrom={durationFilter.priceFrom}
            priceTo={durationFilter.priceTo}
            currencySign={""}
            filter={durationFilter}
            setPriceFilter={setDurationFilter}
          />
        </div>
      )}
    </div>
  );
}
