import React, { ReactNode } from "react";
import Link from "next/link";

import styles from "./LinkButton.module.css";
import { urlReplace } from "../../../services/urlReplace";
import { Img } from "../../elements/Img";

export const OptionalLinkWrapper: React.FC<{
  link: string;
  passHref?: boolean;
  children: React.ReactElement;
}> = ({ link, children, passHref = true }) => {
  return link ? (
    <Link href={urlReplace(link)} passHref={passHref}>
      {children}
    </Link>
  ) : (
    children
  );
};
export interface LinkText {
  text?: string;
  path?: string;
  icon?: string;
  variant?: string;
  center?: boolean;
  arrowReverted?: boolean;
  targetBlanc?: boolean;
  children: React.ReactNode;
}

export default function LinkButton({
  text = "",
  path = "",
  icon,
  variant,
  center,
  targetBlanc,
  arrowReverted,
  children,
}: LinkText) {
  const setStyle = variant === "primary" ? styles.primary : styles.second;
  return (
    <div
      className={`${styles.linkStyleWrapper} ${
        center ? styles.centered : null
      }`}
    >
      {icon && <Img src={icon} className={styles.iconStyle} />}
      <OptionalLinkWrapper link={path ? urlReplace(path) : ""}>
        <a target={targetBlanc ? "_blanc" : ""} className={setStyle}>
          {arrowReverted ? `← ${children}` : `${children} →`}
        </a>
      </OptionalLinkWrapper>
    </div>
  );
}
