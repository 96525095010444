import { IFilter, INumberFilter } from "../EventsFilter/EventsFilter";
import { Courses } from "../../services/wordpressApi";

export const LATIN_SLUG_EN = "latin-language-courses";

export const applyFilters = (
  courses: Courses[],
  allFilters: IFilter[][],
  numberFilters: INumberFilter[]
) => {
  let filteredCourses: Courses[] = courses.slice(0);

  allFilters.map((filters) => {
    filteredCourses = filterCourses(filteredCourses, filters).slice(0);
  });
  numberFilters.map((filters) => {
    filteredCourses = applyNumberFilter(filteredCourses, filters).slice(0);
  });

  return filteredCourses;
};

export const applyNumberFilter = (
  courses: Courses[],
  filter: INumberFilter
) => {
  let filteredCourses: Courses[] = [];

  courses.map((course) => {
    type ObjectKey = keyof typeof course;
    const fieldKey: ObjectKey = filter.query.field as ObjectKey;

    if (typeof course[fieldKey] === "number") {
      if (
        parseInt(course[fieldKey] as string) >= filter.query.valueMin &&
        parseInt(course[fieldKey] as string) <= filter.query.valueMax
      ) {
        filteredCourses.push(course);
      }
    }
  });
  return filteredCourses;
};

export const filterCourses = (
  courses: Courses[],
  filters: IFilter[]
): Courses[] => {
  let bufferedCourses: Courses[] = [];
  let filteredCourses: Courses[] = [];
  let filtered = false;

  filters.map((filter) => {
    if (filter.selected) {
      filteredCourses = filterCoursesByOneFilter(courses, filter).slice(0);
      bufferedCourses.push(...filteredCourses);
      filtered = true;
    }
  });

  return filtered ? bufferedCourses : courses;
};

export const filterCoursesByOneFilter = (
  courses: Courses[],
  filter: IFilter
): Courses[] => {
  let bufferedCourses: Courses[] = courses.slice(0);
  let filteredCourses: Courses[] = [];

  filter.query.map((query) => {
    bufferedCourses.map((course) => {
      type ObjectKey = keyof typeof course;
      const fieldKey: ObjectKey = query.field as ObjectKey;

      if (course[fieldKey] === query.value) {
        filteredCourses.push(course);
      }
    });
    bufferedCourses = filteredCourses.slice(0);
  });
  return bufferedCourses;
};
