import React from "react";
import styles from "./Map.module.css";

export interface IMap {
  mapImage: IMapImage;
}

export interface IMapImage {
  alt: string;
  url: string;
}

const Map: React.FC<IMap> = ({ mapImage }) => {
  return (
    <div className={styles.root}>
      <iframe
        id="map"
        className={styles.imageWrapper}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2659.1554498235496!2d16.353065515819935!3d48.2036216545375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d0791ec51a6b7%3A0xf03b3d499a6b3bf2!2sSpittelberggasse%2022%2C%201070%20Wien%2C%20%C3%96sterreich!5e0!3m2!1sde!2spl!4v1669645260685!5m2!1sde!2spl"
        width="100%"
        height="400"
        style={{ border: 0 }}
        loading="lazy"
      ></iframe>
    </div>
  );
};
export default Map;
