import { Languages } from "../LanguageContext";

const translations = {
  [Languages.English]: {
    notSure: "Not sure on what level you are?",
    contactUs: "Contact us!",
  },
  [Languages.German]: {
    notSure: "Sie wissen nicht, welches Sprachniveau Sie haben?",
    contactUs: "Kontakt aufnehmen",
  },
};

export { translations };
