import { IStep } from "./HowToJoin";
import styles from "./HowToJoin.module.css";
import { Img } from "../elements/Img";

const HowToJoinStep: React.FC<IStep> = ({ stepnumber, icon, title, text }) => {
  return (
    <div className={styles.stepWrapper}>
      <div className={styles.stepNumber}>{stepnumber}</div>
      <div className={styles.graphicWrapper}>
        <Img src={icon?.url} />
      </div>
      <h4 className={styles.stepTitle}>{title}</h4>
      <p className={styles.stepText}>{text}</p>
    </div>
  );
};
export default HowToJoinStep;
