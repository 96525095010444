import React from "react";
import { Img } from "../../elements/Img";
import styles from "./OpinionCard.module.css";

export interface OpinionCardProps {
  opinions: {
    userOpinion: string;
    userAvatar: string;
    userName: string;
    userTitle: string;
  };
}

const OpinionCard: React.FC<OpinionCardProps> = ({ opinions }) => {
  const createMarkup = (text: string) => {
    return { __html: text };
  };
  return (
    <div className={styles.wrapper}>
      <p dangerouslySetInnerHTML={createMarkup(opinions.userOpinion)} />
      <div className={styles.userData}>
        <Img src={opinions.userAvatar} />
        <div>
          <h5 className={styles.userName}>{opinions.userName}</h5>
          <div className={styles.userTitle}>{opinions.userTitle}</div>
        </div>
      </div>
    </div>
  );
};

export default OpinionCard;
