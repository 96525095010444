import { Languages } from "../LanguageContext";

const translations = {
  [Languages.English]: {
    readOurBlogButton: "Read our blog",
  },
  [Languages.German]: {
    readOurBlogButton: "Lesen Sie mehr",
  },
};

export { translations };
