import React, { useContext, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Img } from "../elements/Img";
import LinkButton from "../elements/LinkButton";
import LevelModal from "../Filter/LevelModal/LevelModal";
import LanguageContext from "../LanguageContext";
import styles from "./RegistrationRequirements.module.css";
import { translations } from "./translations";

import infoTooltipIcon from "../elements/EventCard/assets/infoTooltipIcon.svg";

interface IElement {
  listElementTitle?: string;
  listElement?: string;
}

interface IRegistrationRequirements {
  title: string;
  topText: string;
  list: IElement[];
  bottomText?: string;
}

const RegistrationRequirements: React.FC<IRegistrationRequirements> = ({
  title,
  topText,
  list,
  bottomText,
}) => {
  const { language } = useContext(LanguageContext);
  const [levelModalVisible, setLevelModalVisible] = useState(false);

  const showLevelModal = () => {
    setLevelModalVisible(true);
  };

  return (
    <div className={styles.root}>
      <div className={styles.titleWrapper}>
        <h4 className={styles.title}>{title}</h4>
        <Button onClick={showLevelModal} className={styles.levelModalButton}>
          <Img src={infoTooltipIcon} />
        </Button>

        <LevelModal
          modalShow={levelModalVisible}
          modalHide={() => {
            setLevelModalVisible(false);
          }}
        />
      </div>
      <p>{topText}</p>
      <ul>
        {list?.map(({ listElementTitle, listElement }) => (
          <>
            {listElementTitle && (
              <p
                className={styles.answerTitle}
                dangerouslySetInnerHTML={{ __html: listElementTitle }}
              />
            )}
            {listElement && (
              <li
                className={styles.answer}
                dangerouslySetInnerHTML={{ __html: listElement }}
              />
            )}
          </>
        ))}
      </ul>
      {bottomText && <p>{bottomText}</p>}
      <div className={styles.linkWrapper}>
        <LinkButton path="#verify_level" variant="primary">
          {translations[language].verifyLevel}
        </LinkButton>
      </div>
    </div>
  );
};
export default RegistrationRequirements;
