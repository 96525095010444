import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import CourseInformation from "../CourseInformation/CourseInformation";
import PaymentsDiscounts from "../PaymentsDiscounts/PaymentsDiscounts";
import RegistrationRequirements from "../RegistrationRequiremenets/RegistrationRequiremenets";
import styles from "./CourseDetails.module.css";
import {
  COURSE_INFORMATION_ID,
  PAYMENTS_DISCOUNTS,
  REGISTRATION_REQUIREMENTS_ID,
} from "./helpers";

interface ICourseDetails {
  courseInformations: any;
}

const CourseDetails: React.FC<ICourseDetails> = ({
  courseInformations: { attrs },
}) => {
  const [courseInformation, setCourseInformation] = useState(null);
  const [registrationRequirements, setRegistrationRequirements] =
    useState(null);
  const [paymentsDiscounts, setPaymentsDiscounts] = useState(null);

  useEffect(() => {
    attrs.map((block: any) => {
      switch (block.id) {
        case COURSE_INFORMATION_ID:
          setCourseInformation(block.attrs);
          break;
        case REGISTRATION_REQUIREMENTS_ID:
          setRegistrationRequirements(block.attrs);
          break;
        case PAYMENTS_DISCOUNTS:
          setPaymentsDiscounts(block.attrs);
          break;
      }
    });
  }, [attrs]);
  return (
    <div className={styles.root}>
      <Container fluid="sm" className={styles.courseDetailsWrapper}>
        <div className={styles.leftColumn}>
          {courseInformation && (
            // @ts-expect-error
            <CourseInformation {...courseInformation} />
          )}
        </div>
        <div className={styles.rightColumn}>
          {registrationRequirements && (
            // @ts-expect-error
            <RegistrationRequirements {...registrationRequirements} />
          )}
          {paymentsDiscounts && (
            // @ts-expect-error
            <PaymentsDiscounts {...paymentsDiscounts} />
          )}
        </div>
      </Container>
    </div>
  );
};
export default CourseDetails;
