import { Languages } from "../LanguageContext";

const translations = {
  [Languages.English]: {
    filterCourses: "Filter courses",
    goBack: "Go back",
    emptyList: "We are working on the offer for you.",
    loadMore: "Load more",
  },
  [Languages.German]: {
    filterCourses: "Filter courses",
    goBack: "Geh zurück",
    emptyList: "Wir sind dabei, passende Angebote für Sie zu erstellen.",
    loadMore: "Weitere Kurse",
  },
};

export { translations };
