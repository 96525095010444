import React from "react";
import { Container, Row } from "react-bootstrap";
import styles from "./OurStrengths.module.css";
import { Img } from "../elements/Img";
import SingleStrength from "./SingleStrength";
import Link from "next/link";

export interface IStrength {
  icon?: Image;
  titleStrength: string;
  textStrength: string;
  textLink?: string;
  urlLink?: string;
}

interface Image {
  alt: string;
  url: string;
}

interface IOurStrengths {
  title: string;
  subtitle?: string;
  text?: string;
  buttonText?: string;
  buttonUrl?: string;
  strengths: IStrength[];
}

const OurStrengths: React.FC<IOurStrengths> = ({
  title,
  subtitle,
  text,
  buttonText,
  buttonUrl,
  strengths,
}) => {
  return (
    <div className={styles.root}>
      <Container fluid="sm">
        <div>
          <h2 className={styles.title}>{title}</h2>
          {subtitle && <h6 className={styles.subtitle}>{subtitle}</h6>}
          {text && <p className={styles.text}>{text}</p>}
          {buttonUrl && buttonText && (
            <Link passHref href={buttonUrl}>
              <a className={styles.button}>{buttonText}</a>
            </Link>
          )}
        </div>
        <div className={styles.strengthsWrapper}>
          {strengths.map((strength: IStrength) => (
            <SingleStrength {...strength} />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default OurStrengths;
