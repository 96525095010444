import { Languages } from "../LanguageContext";

const translations = {
  [Languages.English]: {
    findUs: "Find us on map",
  },
  [Languages.German]: {
    findUs: "Finden Sie uns auf der Karte",
  },
};

export { translations };
