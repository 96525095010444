import React from "react";
import { Container, Row } from "react-bootstrap";

import styles from "./CourseInformation.module.css";
import SingleInformation from "./SingleInformation";

interface IInformation {
  label: string;
  text: string;
  additionalInfo: string;
}

interface ICourseInformation {
  title: string;
  informations: IInformation[];
}

const CourseInformation: React.FC<ICourseInformation> = ({
  title,
  informations,
}) => {
  return (
    <div className={styles.root}>
      <h4 className={styles.title}>{title}</h4>
      {informations.map((information: IInformation) => (
        <SingleInformation {...information} key={information.label} />
      ))}
    </div>
  );
};
export default CourseInformation;
