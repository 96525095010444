import Link from "next/link";
import React from "react";
import { Container } from "react-bootstrap";
import { urlReplace } from "../../services/urlReplace";
import Button from "../elements/Button";
import { Img } from "../elements/Img";
import styles from "./Facts.module.css";

export interface FactsProps {
  header: string;
  text: string;
  buttonText: string;
  buttonUrl: string;
  facts: {
    image: string;
    values: string;
    text: string;
  }[];
}

const Facts: React.FC<FactsProps> = ({
  header,
  text,
  buttonText,
  buttonUrl,
  facts,
}) => {
  const createMarkup = (text: string) => {
    return { __html: text };
  };
  return (
    <Container className={styles.root}>
      <h4 className="left">{header}</h4>
      {text && (
        <div
          className={styles.richText}
          dangerouslySetInnerHTML={createMarkup(text)}
        />
      )}
      <div className={styles.factsWrapper}>
        {facts.map(({ image, values, text }) => (
          <div className={styles.factCard} key={values}>
            {image && <Img src={image} />}
            <div>
              <h5 className="left green">{values}</h5>
              <div dangerouslySetInnerHTML={createMarkup(text)} />
            </div>
          </div>
        ))}
      </div>
      {buttonText && (
        <div className={styles.buttonWrapper}>
          <Link
            href={urlReplace(buttonUrl || "")}
            passHref
            className={styles.buttonWrapper}
          >
            <a>
              <Button variant={"primary"}>{buttonText}</Button>
            </a>
          </Link>
        </div>
      )}
    </Container>
  );
};

export default Facts;
