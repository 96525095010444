import React from "react";
import styles from "./OurPartners.module.css";
import { Container, Row } from "react-bootstrap";
import { Img } from "../elements/Img";

export interface IPartner {
  icon: IPartnerImage;
}

interface IPartnerImage {
  alt: string;
  url: string;
}

interface IOurPartners {
  title: string;
  partners: IPartner[];
}

const OurPartners: React.FC<IOurPartners> = ({ title, partners }) => {
  return (
    <div className={styles.root}>
      <Container>
        <h3 className={styles.title}>{title}</h3>
        <Row className={styles.partnersWrapper}>
          {partners.map((partner: IPartner, index) => {
            return (
              <div>
                <Img
                  key={`partner-image-${index}`}
                  src={partner.icon.url}
                  alt={partner.icon.alt}
                />
              </div>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};
export default OurPartners;
