import Link from "next/link";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { urlReplace } from "../../services/urlReplace";
import Button from "../elements/Button";
import { Img } from "../elements/Img";
import styles from "./servicesComponent.module.css";

import rightSwirt from "./assets/right-swirt.svg";

export interface ServicesComponentProps {
  header: string;
  buttonText: string;
  buttonUrl: string;
  card: [];
  text: string;
}

const ServicesComponent: React.FC<ServicesComponentProps> = ({
  header,
  buttonText,
  buttonUrl,
  card,
  text,
}) => {
  const createMarkup = (text: string) => {
    return { __html: text };
  };
  return (
    <div className={styles.rootImage}>
      <Img className={styles.rightSwirt} src={rightSwirt} />
      <Container className={styles.root}>
        <Row>
          <Col>
            {header && <h4 className={text && "left"}>{header}</h4>}
            {text && (
              <div
                dangerouslySetInnerHTML={createMarkup(text)}
                className={styles.richText}
              />
            )}
            <div className={styles.wrapper}>
              {card?.map(({ text, icon }, index) => (
                <div className={styles.serviceWrapper} key={index}>
                  <div className={styles.iconWrapper}>
                    <Img src={icon} className={styles.icon} />
                  </div>
                  <div
                    className={styles.contentWrapper}
                    dangerouslySetInnerHTML={createMarkup(text)}
                  />
                </div>
              ))}
            </div>
            {buttonText && (
              <div className={styles.buttonWrapper}>
                <Link
                  href={urlReplace(buttonUrl || "")}
                  passHref
                  className={styles.buttonWrapper}
                >
                  <a>
                    <Button variant={"secondary"}>{buttonText}</Button>
                  </a>
                </Link>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServicesComponent;
