import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./TextWithImages.module.css";
import Button from "../elements/Button";
import Link from "next/link";
import { urlReplace } from "../../services/urlReplace";
import { Img } from "../elements/Img";

import leftElipse from "./assets/left-elipse.svg";

export interface TextWithImagesProps {
  headerMarker?: any;
  header?: string;
  title?: string;
  article?: string;
  button?: string;
  buttonPath?: string;
  linkButton?: string;
  linkButtonPath?: string;
  inverted: boolean;
  image?: { url: string };
  icon?: { url: string };
  contactUsBlock: string;
  textWidth: string;
  fontColor: string;
  backgroundTheme: string;
}

export default function TextWithImages(props: TextWithImagesProps) {
  const { headerMarker: HeaderTag } = props;

  const createMarkup = (text: string) => {
    return { __html: text };
  };

  return (
    <div
      className={styles.root}
      style={{
        backgroundColor: `${props.backgroundTheme}`,
        color: `${props.fontColor}`,
      }}
    >
      {props.backgroundTheme === "#F0F3F5" && (
        <Img className={styles.leftElipse} src={leftElipse} />
      )}
      <Container>
        <Row>
          <Col>
            <div
              className={
                props.inverted ? styles.wrapper : styles.wrapperInverted
              }
            >
              <div
                style={{ width: `${props.textWidth}%` }}
                className={styles.textWrapper}
              >
                {props.header && (
                  <HeaderTag
                    className={
                      props.backgroundTheme === "#2e394d"
                        ? "inverted separator"
                        : "separator"
                    }
                    dangerouslySetInnerHTML={{ __html: props.header }}
                  />
                )}
                {props.title && (
                  <div
                    style={{ color: `${props.fontColor}` }}
                    dangerouslySetInnerHTML={{ __html: props.title }}
                  />
                )}
                {props.article && (
                  <div
                    className={styles.article}
                    dangerouslySetInnerHTML={createMarkup(props.article)}
                  />
                )}
                <div className={styles.buttonWrapper}>
                  {props.button && (
                    <Link href={urlReplace(props.buttonPath || "")} passHref>
                      <a>
                        <Button variant={"primary"} icon={props.icon?.url}>
                          {props.button}
                        </Button>
                      </a>
                    </Link>
                  )}
                  {props.linkButton && (
                    <Link
                      href={urlReplace(props.linkButtonPath || "")}
                      passHref
                    >
                      <a>
                        <Button variant={"secondary"} icon={props.icon?.url}>
                          {props.linkButton}
                        </Button>
                      </a>
                    </Link>
                  )}
                </div>
              </div>
              <div
                style={{
                  width: `calc(${100 - parseInt(props.textWidth)}% - 25px)`,
                }}
                className={styles.imagesWrapper}
              >
                {props.image && (
                  <>
                    <Img src={props.image.url} />
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
