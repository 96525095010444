import { useContext } from "react";
import LanguageContext from "../components/LanguageContext";

import { translations } from "../components/Filter/translations";

export const basicLanguageFilters = () => {
  const { language } = useContext(LanguageContext);

  return [
    {
      title: translations[language].austrian,
      selected: false,
      query: [{ field: "language_id", value: "austrian" }],
    },
    {
      title: translations[language].german,
      selected: false,
      query: [
        {
          field: "language_id",
          value: translations[language].german.toLowerCase(),
        },
      ],
    },
    {
      title: translations[language].english,
      selected: false,
      query: [
        {
          field: "language_id",
          value: translations[language].english.toLowerCase(),
        },
      ],
    },
    {
      title: translations[language].spanish,
      selected: false,
      query: [
        {
          field: "language_id",
          value: "spanish",
        },
      ],
    },
    {
      title: translations[language].french,
      selected: false,
      query: [
        {
          field: "language_id",
          value: "french",
        },
      ],
    },
    {
      title: translations[language].latin,
      selected: false,
      query: [{ field: "language_id", value: "latin" }],
    },
  ];
};

export const basicLevelFilters = [
  {
    title: "A1",
    selected: false,
    query: [{ field: "level", value: "A1" }],
  },
  {
    title: "A2",
    selected: false,
    query: [{ field: "level", value: "A2" }],
  },
  {
    title: "A2/B1",
    selected: false,
    query: [{ field: "level", value: "A2/B1" }],
  },
  {
    title: "B1",
    selected: false,
    query: [{ field: "level", value: "B1" }],
  },
  {
    title: "B1/B2",
    selected: false,
    query: [{ field: "level", value: "B1/B2" }],
  },
  {
    title: "B2",
    selected: false,
    query: [{ field: "level", value: "B2" }],
  },
  {
    title: "B2/C1",
    selected: false,
    query: [{ field: "level", value: "B2/C1" }],
  },
  {
    title: "C1",
    selected: false,
    query: [{ field: "level", value: "C1" }],
  },
  {
    title: "C1/C2",
    selected: false,
    query: [{ field: "level", value: "C1/C2" }],
  },
  {
    title: "C2",
    selected: false,
    query: [{ field: "level", value: "C2" }],
  },
];

export const basicIntensityFilters = () => {
  const { language } = useContext(LanguageContext);

  return [
    {
      title: translations[language].intensive,
      selected: false,
      query: [{ field: "intesity_id", value: "Intensive" }],
    },
    {
      title: translations[language].semiIntensive,
      selected: false,
      query: [{ field: "intesity_id", value: "Semi Intensive" }],
    },
    {
      title: translations[language].regular,
      selected: false,
      query: [{ field: "intesity_id", value: "Regular" }],
    },
  ];
};

export const basicPriceFilter = () => {
  const { language } = useContext(LanguageContext);

  return {
    title: translations[language].price,
    priceFrom: "0",
    priceTo: "2800",
    query: { field: "price", valueMin: 0, valueMax: 2800 },
  };
};

export const basicDurationFilter = () => {
  const { language } = useContext(LanguageContext);

  return {
    title: translations[language].duration,
    priceFrom: "1",
    priceTo: "288",
    query: { field: "durationInMonths", valueMin: 1, valueMax: 288 },
  };
};
