import { Col, Container, Row } from "react-bootstrap";
import styles from "./BlogArticles.module.css";
import React, { useContext, useEffect, useState } from "react";
import { BlogCard } from "../elements/Card/Card";
import { Post } from "../../pages/news-blog-deutsch-sprachschule";
import Button from "../elements/Button";
import { useRouter } from "next/router";
import LanguageContext from "../LanguageContext";
import { translations } from "./translations";
import { Img } from "../elements/Img";

import leftSwirt from "./assets/left-swirt.svg";

export interface IBlogArticles {
  title: string;
  showButton?: boolean;
}

const BlogArticles: React.FC<IBlogArticles> = ({
  title,
  showButton = true,
}: IBlogArticles) => {
  const [blogPosts, setBlogPosts] = useState<Post[] | null>(null);
  const router = useRouter();
  const { language } = useContext(LanguageContext);

  const fetchBlogPosts = async () => {
    return fetch(`/api/blog/posts`, {
      method: "GET",
    }).then((response) => response.json());
  };
  useEffect(() => {
    fetchBlogPosts().then((response) => {
      setBlogPosts(response.posts.slice(0, 3));
    });
  }, []);

  return (
    <div className={styles.root}>
      <Img className={styles.leftSwirt} src={leftSwirt} />
      <Container>
        <h2 className={styles.title}>{title}</h2>
        {blogPosts && (
          <Row>
            {blogPosts.map((post: Post, index) => (
              <Col
                key={JSON.stringify(post) + index}
                xs={12}
                md={6}
                lg={4}
                className={styles.blogPostWrapper}
              >
                <BlogCard
                  blogStyle={true}
                  date={post.date}
                  header={post.title}
                  text={post.excerpt}
                  image={post.image && post.image?.url ? post.image.url : ""}
                  blogPostUrl={post.slug}
                  category={post.category.name}
                />
              </Col>
            ))}
          </Row>
        )}
        {showButton && (
          <Row>
            <Col className={styles.buttonWrapper}>
              <Button
                variant={"primary"}
                onClick={() => router.push("/news-blog-deutsch-sprachschule")}
              >
                {translations[language].readOurBlogButton}
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};
export default BlogArticles;
