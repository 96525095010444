import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { IButtonModal } from "../../services/reducers/withModalText";
import Button from "../elements/Button";
import styles from "./WithModuleText.module.css";

interface IWithModuleTextProps {
  buttons: IButtonModal[];
}

const WithModuleText: React.FC<IWithModuleTextProps> = ({ buttons }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const setModal = (content: string) => {
    setModalContent(content);
    setModalVisible(true);
  };

  return (
    <>
      <Container className={styles.root}>
        <Row>
          <Col sm="12" lg="4" />
          <Col sm="12" lg="8" className={styles.buttonsWrapper}>
            {buttons &&
              buttons.map(({ buttonText, modalText }: IButtonModal) => (
                <Button onClick={() => setModal(modalText)} variant={"primary"}>
                  {buttonText}
                </Button>
              ))}
          </Col>
        </Row>
      </Container>

      <Modal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        aria-labelledby="contained-modal-title-vcenter"
        className={styles.modal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Row className={styles.modalWrapper}>
            <Col>
              <p
                className={styles.modalText}
                dangerouslySetInnerHTML={{ __html: modalContent }}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WithModuleText;
