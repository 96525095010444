import React from "react";

import styles from "./PaymentsDiscounts.module.css";

interface IPaymentsDiscounts {
  title: string;
  priceTitle: string;
  price: string;
  redInfo: string;
  boldTitle?: string;
  text: string;
}

const PaymentsDiscounts: React.FC<IPaymentsDiscounts> = ({
  title,
  priceTitle,
  price,
  redInfo,
  boldTitle,
  text,
}) => {
  return (
    <div className={styles.root}>
      <h4 className={styles.title}>{title}</h4>
      <div className={styles.priceWrapper}>
        <p className={styles.priceTitle}>{priceTitle}</p>
        <h4 className={styles.price}>{price}</h4>
      </div>
      <p className={styles.greenInfo}>{redInfo}</p>
      {boldTitle && <p className={styles.boldTitle}>{boldTitle}</p>}
      <ul>
        <li
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </ul>
    </div>
  );
};
export default PaymentsDiscounts;
