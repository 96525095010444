import * as React from "react";
import parse from "html-react-parser";
import Head from "next/head";

interface ISeoProps {
  header: string;
}

function Seo({ header = "" }: ISeoProps): any {
  return <Head>{parse(header)}</Head>;
}

export default Seo;
