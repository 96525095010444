import { Languages } from "../LanguageContext";

const translations = {
  [Languages.English]: {
    readAllFaqs: "Read all FAQs",
    bookApointment: "Book an appointment",
    sendMessage: "Send us a message",
  },
  [Languages.German]: {
    readAllFaqs: "Zu den FAQ",
    bookApointment: "Termin vereinbaren",
    sendMessage: "Nachricht senden",
  },
};

export { translations };
