import React, { useContext } from "react";
import styles from "./FormalData.module.css";
import LinkButton from "../elements/LinkButton";
import Logo from "../elements/Logo";

interface IAfterLogo {
  textAfterLogo: string;
}

interface IContactUs {
  title: string;
  textUnderLogo: string;
  address: string;
  vatRegistration: string;
  afterLogo?: IAfterLogo[];
}

const FormalData: React.FC<IContactUs> = ({
  title,
  textUnderLogo,
  address,
  afterLogo,
}) => {
  const createMarkup = (text: string) => {
    return { __html: text };
  };

  return (
    <div className={styles.root}>
      <div className={styles.topContactDetails}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.logoWrapper}>
          <Logo />
        </div>
      </div>
      <div className={styles.logoWrapper}>
        <div className={styles.companyDataWrapper}>
          {textUnderLogo && (
            <p
              className={styles.textUnderLogo}
              dangerouslySetInnerHTML={createMarkup(textUnderLogo)}
            />
          )}
          {address && (
            <p
              className={styles.address}
              dangerouslySetInnerHTML={createMarkup(address)}
            />
          )}
          {afterLogo &&
            afterLogo.map(({ textAfterLogo: text }) => (
              <p dangerouslySetInnerHTML={createMarkup(text)} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default FormalData;
