import * as React from "react";
import styles from "./CallToAction.module.css";
import { Img } from "../Img";
import LanguageContext from "../../LanguageContext";
import { Container } from "react-bootstrap";
import Link from "next/link";
import Button from "../Button";
import { urlReplace } from "../../../services/urlReplace";

export interface CallToActionProps {
  header: string;
  text: string;
  buttonText: string;
  buttonUrl: string;
  buttonIcon: { url: string };
  image:  { url: string };
}

export default function CallToAction(props: CallToActionProps) {
  const createMarkup = (text: string) => {
    return { __html: text };
  };
  return (
    <Container className={styles.wrapper}>
      <h4 className={`${styles.header} green`}>{props.header}</h4>
      <p dangerouslySetInnerHTML={createMarkup(props.text)} />
      <div className={styles.buttonWrapper}>
        <Link
          href={urlReplace(props.buttonUrl || "")}
          passHref
          className={styles.buttonWrapper}
        >
          <a>
            <Button variant={"primary"} icon={props.buttonIcon.url}>
              {props.buttonText}
            </Button>
          </a>
        </Link>
      </div>
      <Img src={props.image.url} className={styles.image} />
    </Container>
  );
}
