import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdvantageItem from "./AdvantageItem";
import styles from "./Advantages.module.css";

export interface IAdvantage {
  text: string;
  icon: IImage;
  small?: boolean;
}

interface IImage {
  alt: string;
  url: string;
}

interface IAdvantages {
  title: string;
  advantages: IAdvantage[];
  columnView: boolean;
}

const Advantages: React.FC<IAdvantages> = ({
  title,
  advantages,
  columnView,
}) => {
  return (
    <div className={columnView ? styles.rootSmall : styles.root}>
      <Container fluid="sm">
        {columnView ? (
          <h4>{title}</h4>
        ) : (
          <h2 className={styles.title}>{title}</h2>
        )}
        <Row className={styles.advantagesWrapper}>
          {advantages.map((advantage: IAdvantage) => {
            return (
              <Col
                key={`advantage-item-${advantage.text}`}
                md="auto"
                xs={12}
                sm={6}
                className={styles.advantagesColumn}
              >
                <AdvantageItem {...advantage} small={columnView} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};
export default Advantages;
