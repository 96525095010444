import React, { useContext, useState } from "react";
import { Img } from "../../elements/Img";
import Link from "next/link";
import styles from "./SingleCard.module.css";
import mainImg from "../img/mainImg.svg";
import { urlReplace } from "../../../services/urlReplace";
import LanguageContext from "../../LanguageContext";
import { translations } from "../translations";

export interface SingleCardProps {
  image: string;
  header: string;
  counter?: number;
  url: string;
}

const SingleCard: React.FC<SingleCardProps> = ({
  image,
  header,
  counter,
  url,
}) => {
  const { language } = useContext(LanguageContext);

  return (
    <div className={styles.singleCard}>
      <Img src={image || mainImg} />
      <Link href={urlReplace(url)}>
        <a className="green boldLink">{header}</a>
      </Link>
      <p className="small text">
        {counter} {translations[language].offerts}
      </p>
    </div>
  );
};

export default SingleCard;
